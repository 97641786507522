<template>
    <div class="row">
        <div class="card-body m-0 p-0">
            <div>
                <h3>CÔNG TY CỔ PHẦN CAO SU ĐÀ NẴNG</h3>
                <h3>ĐV: {{ departmentName }}</h3>
            </div>
            <div class="table-responsive" style="overflow-x: auto;">
                <table id="tbl-preview" class="table b-table table-striped table-hover table-bordered">
                    <tr v-for="(row, i) in previewData" :key="i"
                        :class="i >= (previewData.length - 2) ? 'tbl-footer' : ''">
                        <td v-if="i === 0" :colspan="row.length">{{ row[1] }}</td>
                        <template v-else>
                            <td v-for="(cell, j) in row" :key="j" :class="getClasses(cell, j, i)"
                                :rowspan="getRowspan(cell)" v-if="j > 0">
                                {{ cell }}
                            </td>
                        </template>
                    </tr>
                </table>
            </div>
            <div>
                <div style="display: flex;">
                    <div style="margin-left: 20%;">
                        <h3>PHÒNG NHÂN SỰ TIỀN LƯƠNG</h3>
                    </div>
                    <div style="margin-left: 20%;">
                        <h3>THỦ TRƯỞNG ĐƠM VỊ</h3>
                    </div>
                    <div style="margin-left: 25%; text-align: center;">
                        <span>Đà Nẵng, ngày .... tháng .... năm ....</span>
                        <h3>NGƯỜI KIỂM TRA</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        previewData: {
            type: [Array, Object]
        },
        departmentName: {
            type: String,
            required: true
        },
        departmentUnit: {
            type: String,
            required: true
        },
        day: {
            type: Number,
            required: true
        },
        month: {
            type: String,
            required: true
        },
        year: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            rowspanCount: 1,
            skipNextCell: false
        };
    },
    methods: {
        getClasses(cell, columnIndex, rowIndex) {
            if (cell && typeof cell === 'string' && (cell.includes('STT') || cell.includes('Mã NV') || cell.includes('Họ và tên') || cell.includes('Tổng công cơm ca'))) {
                return '';
            }
            return 'max-width-column';
        },
        getRowspan(cell, columnIndex, rowIndex) {
            if (cell && typeof cell === 'string' && (cell.includes('STT') || cell.includes('Mã NV') || cell.includes('Họ và tên') || cell.includes('Tổng công cơm ca'))) {
                return 2;
            }
            return 1;
        }
    }
}
</script>

<style scoped>
.row {
    width: 100%;
}

#tbl-preview {
    border: none;
}

#tbl-preview tr:nth-child(1) td {
    text-align: center;
    border: none;
    font-weight: bold;
    font-size: 22px;
}

.deleted-cell {
    display: none;
}

#tbl-preview tr:last-child {
    font-weight: bold;
}

.center {
    align-items: center;
    justify-content: center;
}
</style>

<template>
  <!-- Page content -->
    <div id="main-default" class="container-fluid">
      <div class="card col-xl-12">
        <div class="row">
          <div class="col-md-6 row mt-3">
              <div class="col-xl-12">
              <h1 class="page-title mt-2">
                {{ $t('menu.workSchedule') }}
              </h1>
              </div>
            <div class="col-xs-12 col-md-12">
                <treeselect v-model="departmentid" :multiple="false" 
                :options="departmentOption" :default-expand-level="Infinity"
                @input="changeDepartment"
                :noChildrenText="$t('label.noNodeChildren')" :placeholder="$t('label.chooseDepartment')" />
              </div>
          </div>
          <div class="col-md-6 mt-3">
            <h3 class="mt-4 pb-4">Thiết lập ca</h3>

            <div class="row align-items-center justify-content-center mt-3">
              <div class="col-md-4 col-xs-12 mb-2">
                <label>Loại ca</label>
                <treeselect v-model="shiftsId" :multiple="false" :options="shiftsOptions" :flat="false"
                          :default-expand-level="1" :placeholder="$t('label.chooseShift')" 
                          />
              </div>
              <div class="col-md-4 col-xs-12 mb-2">
                <label>{{ $t('label.start') }}</label>
                <flat-pickr v-model="startDateString" :config="configDateTimePicker"
                            name="birthdate"
                            class="form-control"></flat-pickr>
              </div>
              <div class="col-md-4 col-xs-12 mb-2">
                <label>{{ $t('label.end') }}</label>
                <flat-pickr v-model="endDateString" :config="configDateTimePicker"
                            name="birthdate"
                            class="form-control"></flat-pickr>
              </div>
              
              <div class="col-md-12 col-xs-12 my-2">
                  <div class="row justify-content-center">
                      <div class="col-md-4">
                          <button class="btn btn-success bg-gradient-success btn-block" @click="addWorkschedule()">Thêm mới</button>
                      </div>
                      <!-- <div class="col-md-3">
                          <button class="btn btn-success btn-block">Lưu</button>
                      </div> -->
                      <div class="col-md-4">
                          <button class="btn btn-danger bg-gradient-danger btn-block">Xóa</button>
                      </div>
                  </div>
              </div>

            </div>
          </div>
        </div>
        <div class="row mt-3">
            <div class=" col-xs-12 col-md-6">
                <div class="card">
                  <div class="card-body m-0 p-0 table-responsive">
                    <!-- <h>Danh sách nhân sự</h -->
                    <b-table
                      bordered="bordered"
                      class=" default-table"
                      hover
                      :items="dataTable"
                      :fields="fields"
                      responsive
                      id="table-staff"
                      @row-clicked="rowClickHandler"
                    >
                    <template #head(checkbox)="data">
                      <b-form-checkbox
                        @click.native.stop
                        @change="selectAll(data)"
                        v-model="allSelected"
                      >
                      </b-form-checkbox>
                    </template>
                    <template #cell(checkbox)="data">
                      <b-form-checkbox
                        @click.native.stop
                        :value="data.item.id"
                        v-model="userSelected"
                      >
                      </b-form-checkbox>
                    </template>
                    </b-table>
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRecord"
                      :per-page="perPage"
                      align="right"
                      size="sm"
                      class="m-4"
                      aria-controls="table-staff"
                      v-on:click.native="getEmployee()"
                    ></b-pagination>
                  </div>
                </div>
              </div>
              <div class=" col-xs-12 col-md-6">
                <div class="card">
                  <div class="card-body m-0 p-0 table-responsive">
                    <b-table
                      bordered="bordered"
                      class=" default-table"
                      hover
                      :items="dataTableHistory"
                      :fields="fieldsHisotry"
                      responsive
                      :current-page="currentPage"
                      :per-page="perPage"
                      id="table-shifthistory"
                    >
                      <template #cell(created_date)="data">
                        {{convertDate(data.item.created_date)}}
                      </template>
                      <template #cell(action)="data">
                          <span>{{$t(data.item.action, { name: data.item.shifts_name })}}</span>
                      </template>
                    </b-table>
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="dataTableHistory.length"
                      :per-page="perPage"
                      align="right"
                      size="sm"
                      class="m-4"
                      aria-controls="table-inouthistory"
                    ></b-pagination>
                  </div>
                </div>
              </div>
        </div>
      </div>
    </div>
  </template>
  <script lang="js">
  import apiClient from '@/axios'
  import apiServer from '@/axiosServer'
  import moment from 'moment'
  import Treeselect from '@riophae/vue-treeselect'
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import PulseLoader from '@/components/PulseLoader.vue'
  import FlatpickrLanguages from "flatpickr/dist/l10n"
  import flatPickr from 'vue-flatpickr-component'
  import 'flatpickr/dist/flatpickr.css'
  
  export default {
    components: { Treeselect, PulseLoader,flatPickr },
    data () {
      return {
          loading: false,
          dataTable: [],
          perPage: 25,
          shiftsId: null,
          currentPage: 1,
          allSelected: false,
          totalRecord: 0,
        fields: [
        {
          label: "",
          key: "checkbox",
          class: "align-middle",
          sortable: false,
        },
          {
            label: this.$t('label.employeeCode'),
            key: 'aliasID',
            class: 'align-middle',
            sortable: false
          },
          {
            label: this.$t('label.fullName'),
            key: 'fullName',
            class: 'align-middle',
            sortable: false
          },
          {
            label: this.$t('label.jobPosition'),
            key: 'position',
            class: 'align-middle',
            sortable: false
          }
        ],
        userSelected: [],
        startDateString: '',
        endDateString: '',
        configDateTimePicker: {
        enableTime: true,
        dateFormat: 'd/m/Y H:i:ss',
        time_24hr: true,
        locale: FlatpickrLanguages["en"],
        shorthandCurrentMonth: true
      },
        dataTableHistory: [],
        fieldsHisotry: [
          {
            label: this.$t('label.dateTime'),
            key: 'created_date',
            class: 'align-middle',
            sortable: false
          },
          {
            label: this.$t('label.action'),
            key: 'action',
            class: 'align-middle',
            sortable: false
          }
          ],
        departmentid: null,
        departmentOption: [],
        shiftsOptions : []
      }
    },
    mounted () {
      this.getDepartment();
      this.getEmployee();
      this.getShiftoption();
      this.startDateString = this.getNowDate() + ' 00:00:00'
      this.endDateString = this.getNowDate() + ' 23:59:00'
      if(this.$store.getters.locale == 'vi'){
      this.configDateTimePicker.locale = FlatpickrLanguages["vn"]
    };
    },
    methods: {
      async getDepartment() {
        let res
        try {
          res = await apiServer.post('api/v1/department/list', {})
        } catch (error) {}
        if (res && res.data) {
          this.departmentOption = res.data;
          console.log(this.departmentOption);
          
        }
      },
      selectAll() {
      if (this.allSelected == true) {
        this.userSelected = this.dataTable.map((x) => x.id);
      } else {
        this.userSelected = [];
      }
    },
      async getShiftoption() {
        let res
        try {
          res = await apiServer.post('/api/v1/shifts/get', {})
        } catch (error) {}
        if (res && res.data) {
          this.shiftsOptions = res.data;
          console.log(this.shiftsOptions);
        }
      },
      getNowDate () {
      let today = new Date()
      const dd = String(today.getDate()).padStart(2, '0')
      const mm = String(today.getMonth() + 1).padStart(2, '0')
      const yyyy = today.getFullYear()
      today = dd + '/' + mm + '/' + yyyy
      return today
    },
      async getEmployee () {
        this.loading = true;
        let placeID = this.$store.getters.placeID;
        let dpid = (this.departmentid != null && this.departmentid != undefined) ?
        [this.departmentid] : null;
        this.dataTableHistory = [];
        this.dataTable = [];
        let res
        try {
          res = await apiServer.post('api/v1/user/get-list-users-v2', {
            placeID: placeID,
            userName: this.userName,
            department: dpid,
            currentPage: this.currentPage,
            rowPerPage: this.perPage
          })
        } catch (error) {
          this.loading = false;
        }
        if (res && res.data) {
            this.dataTable = res.data.items;
            this.totalRecord = res.data.totalRecord;
            this.loading = false;
        }
      },
      async addWorkschedule() {
      if (this.shiftsId == null || this.shiftsId == undefined) {
        alert(this.$t("validate.message.noDepartmentSelected"));
        return false;
      }
      let res;
      // try {
      //   res = await apiServer.post("api/v1/user/change-department", {
      //     departmentId: this.shiftsId,
      //     userIds: this.userSelected,
      //   });
      // } catch (error) {}
      // toDate:  this.convertTimestampSendDate(this.endDateString),
      //     fromDate: this.convertTimestampSendDate(this.startDateString),
      console.log(this.shiftsId ,this.userSelected );
      console.log(this.endDateString ,this.startDateString );
      // if (res && res.data) {
      //   this.modalMoveDepart = false;
      //   this.userSelected = false;
      //   await this.getEmployee();
      //   this.$notify({
      //     group: "bottomnotification",
      //     title: this.$t("label.notification"),
      //     text: this.$t(res.data.message),
      //     position: "bottom-center",
      //   });
      // }
      this.userSelected = [];
    },
      async getShiftHistory(uid){
        this.dataTableHistory = [];
        let res
        try {
          res = await apiServer.post('api/v1/shift/history/get-by-user', {
              userid: uid
          })
        } catch (error) {}
        if (res && res.data) {
          this.dataTableHistory = res.data;
        }
      },
      changeDepartment(){
          setTimeout(() => {this.getEmployee()}, 500);
      },
      convertDate(inputDate) {
        if (inputDate == '' || inputDate == null) return;
        return  moment(inputDate).format("DD/MM/yyyy HH:mm:ss");
      },
      rowClickHandler(record, index) {
        this.getShiftHistory(record.id);
      }
    }
  }
  </script>
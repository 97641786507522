<template>
  <!-- Page content -->
  <div id="main-default" class="container-fluid">
    <div class="card col-xl-12">
      <div class="row">
        <div class="col-xl-12">
          <div class="row">
            <div class="col-md-6 col-xs-12">
              <h1 class="page-title mt-2">
                {{ $t("title.managementLeave") }}
              </h1>
              <p class="text-muted">
                {{ $t("label.totalLeave") }}  {{dataTable.length}}
              </p>
            </div>
          </div>
        </div>
        <div class="col-xl-12">
          <div class="row">
            <div class="col-xs-12 col-md-3">
              <input
                type="text"
                :placeholder="$t('label.staffsName')"
                class="form-control"
                v-model="userName"
              />
            </div>
            <div class="col-xs-12 col-md-3">
              <b-form-group>
                <select v-model="status" class="form-control">
                  <option
                    v-for="status in listStatus"
                    v-bind:value="status.value"
                  >
                    {{ status.text }}
                  </option>
                </select>
              </b-form-group>
            </div>
            <div class="col-md-3 col-xs-12">
              <b-button
                variant="btn btn-info  btn-outline-primary w-100"
                @click="search('search')"
              >{{$t('label.search')}}
              </b-button>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-xs-12 mb-2">
          <b-form-checkbox class="b-form-checkbox" v-model="autoApprove" @change="setAutoApprove($event)">
            {{$t('label.autoApprove')}}
          </b-form-checkbox>
        </div>
        <div class="col-md-6 col-xs-12 mb-2 text-right">
          <button class="btn btn-primary mr-2"
                  @click="approveList()">
            <font-awesome-icon icon="list" />
            {{ $t('label.approveList') }}
          </button>
        </div>
        <div class="col-xl-12">
          <div class="row mt-3">
            <div class="col-xl-12">
              <div class="card">
                <div class="card-body m-0 p-0 table-responsive">
                  <b-table
                    bordered="bordered"
                    class="default-table"
                    hover
                    :items="dataTable"
                    :fields="fields"
                    responsive
                    :current-page="currentPage"
                    :per-page="perPage"
                    id="table-approve-leave"
                    @row-clicked="showInfoDetail"
                    show-empty
                  >
                    <template #head(checkbox)="data">
                      <b-form-checkbox @click.native.stop @change="selectAll(data)" v-model="allSelected">
                      </b-form-checkbox>
                    </template>
                    <template #cell(checkbox)="data">
                      <b-form-checkbox v-if="data.item.approved == 0" @click.native.stop :value="data.item.id" v-model="leaveSelected">
                      </b-form-checkbox>
                    </template>
                    <template #cell(infoUser)="data">
                      <div class="row">
                        <div class="col-2 p-0">
                          <img
                            :src="data.item.avatar"
                            alt=""
                            height="90px"
                            width="80px"
                          />
                        </div>
                        <div class="col-10">
                          <small class="text-muted">{{data.item.personID}}</small>
                          <p class="m-0">{{data.item.fullName}}</p>
                          <small class="text-muted">{{data.item.email}}</small>
                        </div>
                      </div>
                    </template>
                    <template #cell(browser)="data">
                      <p class="m-0">{{data.item.userApprove}}</p>
                    </template>
                    <template #cell(reason)="data">
                      <div class="m-0">{{ data.item.reason | truncate(40, '...') }}</div>
                      <small class="text-danger">{{$t('label.timeDay')}}:
                        <template v-if="data.item.off_work_from != data.item.off_work_to">
                          {{ convertDate(data.item.off_work_from) + ' - ' + convertDate(data.item.off_work_to) }}
                        </template>
                        <template v-else>
                          {{ convertDate(data.item.off_work_to) }}
                        </template>
                      </small>
                    </template>
                    <template #cell(status)="data">
                      <div class="m-0 text-white">
                        <template v-if="data.item.approved == 3">
                          <span
                            style="
                            background-color: red;
                            border-radius: 10px;
                            line-height: 20px;
                            font-weight: bold;
                            padding: 3px 10px;
                            font-size: 13px;
                          "
                          >{{ $t('label.refuse')}}</span>
                        </template>
                        <template v-else-if="data.item.approved == 2">
                          <span
                            style="
                            background-color: lightgreen;
                            border-radius: 10px;
                            line-height: 20px;
                            font-weight: bold;
                            padding: 3px 10px;
                            font-size: 13px;
                          "
                          >{{ $t('label.pending')}}</span>
                        </template>
                        <template v-else-if="data.item.approved == 1">
                          <span
                            style="
                            background-color: rgb(45 189 76);
                            border-radius: 10px;
                            line-height: 20px;
                            font-weight: bold;
                            padding: 3px 10px;
                            font-size: 13px;
                          "
                          >{{ $t('label.agree')}}</span>
                        </template>
                        <template v-else>
                          <span
                            style="
                            background-color: rgb(251 99 64);
                            border-radius: 10px;
                            line-height: 20px;
                            font-weight: bold;
                            padding: 3px 10px;
                            font-size: 13px;
                          "
                          >{{ $t('label.waitingForApprove')}}</span>
                        </template>
                      </div>
                    </template>
                    <template #cell(function)="data">
                      <button
                        class="btn btn-primary btn-sm"
                        @click="showEdit(data.item.id)"
                        v-b-tooltip.hover
                        :title="$t('label.approval')"
                        :disabled="checkDisable(data.item.approved)"
                      >
                        <font-awesome-icon icon="check"/>
                      </button>
                    </template>
                    <template #empty="scope">
                      {{$t('notify.message.nodata')}}
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="dataTable.length"
                    :per-page="perPage"
                    align="right"
                    size="sm"
                    class="m-4"
                    aria-controls="table-approve-leave"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      v-model="modalDelete"
      ref="modal-delete"
      hide-header
      hide-footer
      header-bg-variant="secondary"
      modal-class="modal-delete text-center"
      centered
      size="md"
    >
      <div>
        <div class="modal-delete--warning">
          <div class="modal-delete--warning__content">!</div>
        </div>
        <div>
          <h2 class="text-warning">{{ $t("label.areYouSure") }}?</h2>
        </div>
        <div>{{ $t("label.sureLeave") }}</div>
        <div class="mt-3">
          <b-button variant="primary" @click="deleteLeave(idDelete)"
          >{{ $t("label.iAgree") }}
          </b-button>
          <b-button variant="secondary" @click="hideModalDelete"
          >{{ $t("label.cancel") }}
          </b-button>
        </div>
      </div> </b-modal
    ><!-- modal delete  -->
    <b-modal
      v-model="modalApproval"
      id="modal-approval"
      hide-header
      hide-footer
      header-bg-variant="secondary"
      modal-class="modal-delete text-center"
      centered
      size="md"
    >

      <div>
        <div class="modal-delete--warning">
          <div class="modal-delete--warning__content">!</div>
        </div>
        <div>
          <h2 class="text-warning">{{ $t("label.areYouSure") }}?</h2>
        </div>
        <div>{{ $t("label.sureApproveLeave") }}</div>
        <div class="mt-3">
          <b-button variant="primary" @click="approvalLeave(1)"
          >{{ $t("label.iAgree") }}
          </b-button>
          <b-button variant="primary" @click="deniedLeave()"
          >{{ $t("label.refuse") }}
          </b-button>
          <b-button variant="secondary" @click="$bvModal.hide('modal-approval')"
          >{{ $t("label.cancel") }}
          </b-button>
        </div>
      </div> </b-modal
    ><!-- modal modalApproval  -->

    <b-modal
      v-model="modalInfoDetail"
      ref="modal-info-detail"
      hide-header
      hide-footer
      header-bg-variant="secondary"
      modal-class="modal-info-detail text-center"
      centered
      size="lg"
    >
      <div>
        <div>
          <table class="table table-bordered">
            <thead class="thead-light">
            <tr >
              <th style="text-transform: none;font-size: 18px;">{{ $t('label.sender') }}</th>
            </tr>
            </thead>
            <tr class="text-left">
              <td>
                <p>{{infoSenderDetail.fullName}}</p>
                <p>{{infoSenderDetail.email}}</p>
              </td>
            </tr>
            <tr class="text-left">
              <td colspan="2">
                <template v-if="infoSenderDetail.approved == 0">
                  <span
                    style="
                    background-color: rgb(251 99 64);
                    border-radius: 10px;
                    line-height: 20px;
                    font-weight: bold;
                    padding: 3px 10px;
                    font-size: 13px;
                    color: #fff;
                  "
                  >{{ $t('label.waitingForApprove')}}</span>
                </template>
                <template v-else-if="infoSenderDetail.approved == 1">
                  <span
                    style="
                    background-color: rgb(45 189 76);
                    border-radius: 10px;
                    line-height: 20px;
                    font-weight: bold;
                    padding: 3px 10px;
                    font-size: 13px;
                    color: #fff;
                  "
                  >{{ $t('label.agree')}}</span>
                </template>
                <template v-else-if="infoSenderDetail.approved == 2">
                  <span
                    style="
                    background-color: lightgreen;
                    border-radius: 10px;
                    line-height: 20px;
                    font-weight: bold;
                    padding: 3px 10px;
                    font-size: 13px;
                  "
                  >{{ $t('label.pending')}}</span>
                </template>
                <template v-else>
                  <span
                    style="
                    background-color: red;
                    border-radius: 10px;
                    line-height: 20px;
                    font-weight: bold;
                    padding: 3px 10px;
                    font-size: 13px;
                    color: #fff;
                  "
                  >{{ $t('label.refuse')}}</span>
                </template>
              </td>
            </tr>
            <thead class="thead-light">
            <tr >
              <th colspan="2" style="text-transform: none;font-size: 18px;">{{$t("label.reasonForLeave")}}</th>
            </tr>
            </thead>
            <tr class="text-left">
              <td colspan="2">
                <p>
                  <strong>{{$t('label.timeDay')}}:</strong>
                  <template v-if="infoSenderDetail.off_work_from != infoSenderDetail.off_work_to">
                    {{ convertDate(infoSenderDetail.off_work_from, 'dd/MM/yyyy') + ' - ' + convertDate(infoSenderDetail.off_work_to, 'dd/MM/yyyy') }}
                  </template>
                  <template v-else>
                    {{ convertDate(infoSenderDetail.off_work_to, 'dd/MM/yyyy') }}
                  </template>

                </p>
                <p><strong>{{$t('label.reason')}}:</strong> {{infoSenderDetail.reason}}</p>
              </td>
            </tr>

            <thead class="thead-light" v-if="infoSenderDetail.approved == 2">
            <tr >
              <th colspan="2" style="text-transform: none;font-size: 18px;">{{$t("label.reasonDenied")}}</th>
            </tr>
            </thead>
            <tr class="text-left" v-if="infoSenderDetail.approved == 2">
              <td colspan="2">
                <p><strong>{{$t('label.reason')}}:</strong> {{infoSenderDetail.reason_denied}}</p>
              </td>
            </tr>
          </table>
        </div>

        <div class="mt-3">
          <b-button variant="secondary" @click="hideModalInfoDetail"
          >{{ $t("label.close") }}
          </b-button>
        </div>
      </div> </b-modal
    ><!-- modal show Info Detail  -->
    <b-modal
      id="bv-modal-denied"
      v-model="modalDenied"
      ref="modal-approval"
      hide-header
      hide-footer
      centered
      size="md">
      <b-form>
        <b-form-group
          id="input-group-2"
          :label="$t('label.reasonDenied')"
          label-for="dn-2">
          <textarea
            class="form-control form-control-sm"
            id="dn-2"
            :placeholder="$t('label.reasonDenied')"
            required
            v-model="reasonDenied"
            rows="3"
          ></textarea>
          <div class="error-message w-100 clear text-left">
            {{ msg['reason'] ? $t(msg['reason']) : ''  }}
          </div>
        </b-form-group>
      </b-form>
      <div class="d-block text-center">
        <div class="text-center">
          <b-button class="btn btn-danger"  @click="approvalLeave(3)">{{
              $t('label.save')
            }}
          </b-button>
          <b-button class="btn btn-default" @click="$bvModal.hide('bv-modal-denied')">{{
              $t('label.cancel')
            }}
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import apiClient from "@/axios"
import apiServer from "@/axiosServer"
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import FlatpickrLanguages from "flatpickr/dist/l10n"
export default {
  components: {
    flatPickr
  },
  data() {
    return {
      modalDelete: false,
      modalInfoDetail: false,
      dataTable: [],
      showDeleteSuccess: false,
      currentPage: 1,
      perPage: 25,
      totalTakeLeave: 5,
      showApprovalSuccess: false,
      modalApproval: false,
      modalAddLeave:false,
      fields: [
        {
          label: '',
          key: 'checkbox',
          class: 'align-middle',
          sortable: false
        },
        {
          label: this.$t("label.staffsName"),
          key: "infoUser",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t("label.leaveType"),
          key: "name",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t("label.reason"),
          key: "reason",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t("label.status"),
          key: "status",
          class: "align-middle",
          sortable: false,
        },
        {
          label: "",
          key: "function",
          class: "align-middle text-center",
          sortable: true,
        },
      ],
      infoSenderDetail: [],
      editLeave:[],
      addNewsuccess:false,
      addNewError:false,
      reasonForLeave:'',
      idPerson:'',
      leaveHalfShift: false,
      leaveTypeHalf: null,
      optionsIdPerson: [],
      dateSelect: '',
      configDateTimePicker: {
        dateFormat: 'd/m/Y',
        mode: "range",
        locale: FlatpickrLanguages["en"],
        shorthandCurrentMonth: true
      },
      options: [],
      idBranch: null,
      branchs: [],
      status:null,
      listStatus: [
        { text: this.$t('label.status'), value: null },
        { text: this.$t('label.waitingForApprove'), value: 0 },
        { text: this.$t('label.approved'), value: 1 },
        { text: this.$t('label.pending'), value: 2 },
        { text: this.$t('label.refuse'), value: 3 }
      ],
      userName:'',
      msg:[],
      modalDenied: false,
      reasonDenied: '',
      leaveType: null,
      leaveTypes: [],
      leaveSelected: [],
      allSelected: false,
      autoApprove: false
    };
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        text = text.substring(0, length);
        text = text.substring(0, text.lastIndexOf(' '));
        return text + suffix;
      } else {
        return text;
      }
    }
  },
  mounted() {
    this.getListApproval();
    this.getAutoApprove();
    if(this.$store.getters.locale == 'vi'){
      this.configDateTimePicker.locale = FlatpickrLanguages["vn"]
    };
  },
  methods: {
    hideModalDelete() {
      this.$refs["modal-delete"].hide();
    },

    hidenModalApproval() {
      this.$refs["modal-approval"].hide();
    },

    hideModalInfoDetail() {
      this.$refs["modal-info-detail"].hide();
    },

    showDelete(idDelete) {
      this.idDelete = idDelete;
      this.showDeleteSuccess = false;
      this.modalDelete = true;
    },
    showInfoDetail(id) {
      this.modalInfoDetail = true;
      this.infoSenderDetail =  id;
    },
    deniedLeave(){
      this.modalDenied = true;
    },
    checkDisable(status) {
      return status != 0 && status != 2;
    },
    async getAutoApprove() {
      let res;
      try{
        res = await apiServer.get('/api/v1/user/get-auto-approve',{});
        if (res && res.data) {
          this.autoApprove = res.data;
        }
      }
      catch(error) {
        console.log(error);
      }
    },
    async setAutoApprove(event) {
      let aap = (event == true) ? 1: 0;
      let res;
      try{
        res = await apiServer.post('/api/v1/user/update-auto-approve',{
          auto_approve: aap
        });
        if (res && res.data) {
          this.$notify({
            group: 'bottomnotification',
            title: this.$t('label.notification'),
            text: this.$t(res.data.message),
            position:'bottom-center'
          });
        }
      }
      catch(e) {
        console.log(e);
      }
    },
    async getListApproval(){
      this.dataTable = [];
      let res;
      try{
        res = await apiServer.post('/api/v1/leave/get-approval',{
          status: this.status,
          username: this.userName.trim()
        });
        if (res && res.data) {
          this.dataTable = res.data
        }
      }
      catch (e) {
        console.log(e);
      }
    },

    async deleteLeave(id){
      let res;
      try{
        res = await apiServer.post('/api/v1/leave/delete',{
          id: id,
        });
        if (res && res.data) {
          this.modalDelete = false;
          this.$notify({
            group: 'bottomnotification',
            title: this.$t('label.notification'),
            text: this.$t(res.data.message),
            position:'bottom-center'
          });
          this.getListLeave();
        }
      }
      catch (e) {
        console.log(e);
      }
    },
    async approvalLeave(status){
      let res;
      try{
        res = await apiServer.post('/api/v1/leave/approve',{
          leaveid: this.editLeave.id,
          approve_status: status,
          reason_denied: this.reasonDenied.trim()
        });
        this.modalDenied = false;
        this.modalApproval = false;
        if (res && res.data) {
          this.getListApproval();
          this.$notify({
            group: 'bottomnotification',
            title: this.$t('label.notification'),
            text: this.$t(res.data.message),
            position:'bottom-center'
          });
          this.getListLeave();
        }
      }
      catch (error) {
        if (error.response && error.response.status === 400) {
          this.msg = (error.response.data)
        }
      }
    },
    async approveList() {
      if (this.leaveSelected.length == 0) {
        alert(this.$t('error.message.noSelectLeave'));
        return false;
      }
      let listId = this.leaveSelected.join(",");
      let res;
      try{
        res = await apiServer.post('/api/v1/leave/approve-list',{
          leave_list_id: listId,
          approve_status: 1,
          reason_denied: null
        });
        if (res && res.data) {
          this.getListApproval();
          this.editLeave = [];
          this.$notify({
            group: 'bottomnotification',
            title: this.$t('label.notification'),
            text: this.$t(res.data.message),
            position:'bottom-center'
          });
        }
        this.modalDenied = false;
        this.modalApproval = false;
      }
      catch (error) {
        if (error.response && error.response.status === 400) {
          this.msg = (error.response.data)
        }
      }
    },
    selectAll () {
      if (this.allSelected == true){
        this.leaveSelected = this.dataTable.filter(x => x.approved == 0).map(x => x.id);
      }
      else {
        this.leaveSelected = [];
      }
    },
    search () {
      this.getListApproval();
    },

    convertDate(inputDate) {
      if (inputDate == undefined || inputDate == null) return '';
      return  moment(inputDate).format("DD/MM/yyyy");
    },

    tookABreak(dateId){
      let today = new Date()
      let dt = new Date(dateId);
      let dat2 = today.getTime() - dt.getTime();
      let dat3 = Math.ceil(dat2 / (1000 * 60 * 60 * 24));
      return dat3;
    },
    convertTimestampSendDate (date) {
      const dateString = date;
      const changedDate = dateString.replace(/(..)\/(..)\/(....)/, '$3-$2-$1');
      var date = new Date(changedDate);
      return moment(date).format('yyyy-MM-DD');
    },
    showEdit(id){
      this.editLeave = {
        id: id,
      };
      this.showApprovalSuccess = false;
      this.modalApproval = true;

    }


  }
};
</script>

<style lang="scss" scoped>
.show-user-list{
  padding:5px 0;
  .col-2{
    img{
      height: 50px;
    }
  }
  .col-10{
    padding: 0;
    h5{margin-bottom: 0;}
    p{
      color: #333;
      margin-bottom: 0;
    }
    &:hover{
      h5{color: #fff;}
      p{
        color: #fff;
        margin-bottom: 0;
      }
    }
  }
  &:hover{
    outline: none;
  }
}
.table p{
  word-wrap: break-word;
  white-space: break-spaces;
}
</style>
<style lang="scss">
.b-form-checkbox {
  margin-bottom: 1.5rem;
  label{
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }
}
</style>

<template>
  <div id="main-default" class="container-fluid">
    <div v-show="isInProgress" class="load-submit">
      <font-awesome-icon icon="spinner" />
    </div>
    <div class="card col-12">
      <div class="row info-user">
        <div class="col-12 col-md-3">
          <div class="info-user__image">
            <span><img :src="avatarUser" alt="" /></span>
          </div>
        </div>
        <div class="col-12 col-md-9 info-user-desc">
          <div style="width: 100%">
            <div class="info-user-desc--title">
              <h2>{{ nameUser }}</h2>
              <p style="margin-bottom: 0px">Email: {{ emailUser }}</p>
              <p style="margin-bottom: 0px">
                {{ $t("label.phoneNumber") }}: {{ phoneNumberUser }}
              </p>
              <p style="margin-bottom: 0px">
                {{ $t("label.branch") }} : {{ branchName }}
              </p>
              <p>{{ $t("label.company") }} : {{ companyUser }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card col-12">
      <h3 class="mt-2">{{ $t("label.staffInformation") }}</h3>
      <div>
        <div class="information-user-collapse">
          <div v-b-toggle:my-collapse class="information-user-collapse__title">
            <span class="when-open"
              ><font-awesome-icon icon="caret-down" /></span
            ><span class="when-closed"
              ><font-awesome-icon icon="caret-right"
            /></span>
            {{ $t("label.mainInformation") }}
          </div>
          <b-collapse
            id="my-collapse"
            visible
            class="information-user-collapse__body"
          >
            <div class="row">
              <div class="col-12 col-md-3">
                <b-form-group class="required-mess" :label="$t('label.name')">
                  <b-form-input v-model="nameUser"></b-form-input>
                  <div class="error-message w-100 clear text-left">
                    {{
                      msg["fullName"] && msg["fullName"][0]
                        ? $t(msg["fullName"][0])
                        : ""
                    }}
                  </div>
                </b-form-group>
              </div>
              <div class="col-12 col-md-3">
                <b-form-group
                  class="required-mess"
                  :label="$t('label.userName')"
                >
                  <b-form-input
                    v-model="userName"
                    required
                    disabled
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-12 col-md-3">
                <b-form-group label="Email">
                  <b-form-input v-model="emailUser"></b-form-input>
                  <div class="error-message w-100 clear text-left">
                    {{
                      msg["email"] && msg["email"][0] ? $t(msg["email"][0]) : ""
                    }}
                  </div>
                </b-form-group>
              </div>
              <div class="col-12 col-md-3">
                <b-form-group :label="$t('label.phoneNumber')">
                  <b-form-input v-model="phoneNumberUser"></b-form-input>
                  <div class="error-message w-100 clear text-left">
                    {{
                      msg["phone"] && msg["phone"][0] ? $t(msg["phone"][0]) : ""
                    }}
                  </div>
                </b-form-group>
              </div>
              <div class="col-12 col-md-3">
                <b-form-group :label="$t('label.gender')">
                  <select v-model="genderUser" class="form-control">
                    <option
                      v-for="gender in genders"
                      v-bind:value="gender.value"
                    >
                      {{ gender.text }}
                    </option>
                  </select>
                </b-form-group>
              </div>
              <div class="col-12 col-md-3">
                <b-form-group :label="$t('label.address')">
                  <b-form-input v-model="addressUser"></b-form-input>
                </b-form-group>
              </div>
              <div class="col-12 col-md-3">
                <b-form-group :label="$t('label.dateOfBirth')">
                  <flat-pickr
                    v-model="dateOfBirthUser"
                    :config="configDateTimePicker"
                    name="birthDate"
                    class="form-control"
                  ></flat-pickr>
                </b-form-group>
              </div>
              <div class="col-12 col-md-3">
                <b-form-group :label="$t('label.idCard')">
                  <b-form-input
                    type="number"
                    v-model="idCardUser"
                    required
                  ></b-form-input>
                  <div class="error-message w-100 clear text-left">
                    {{
                      msg["cardID"] && msg["cardID"][0]
                        ? $t(msg["cardID"][0])
                        : ""
                    }}
                  </div>
                </b-form-group>
              </div>
              <div class="col-12 col-md-3">
                <b-form-group
                  class="required-mess"
                  :label="$t('label.employeeCode')"
                >
                  <b-form-input
                    type="text"
                    v-model="aliasID"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-12 col-md-3">
                <b-form-group
                  class="required-mess"
                  :label="$t('label.checkInCode')"
                >
                  <b-form-input
                    type="text"
                    v-model="idCheckIn"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-12 col-md-3">
                <b-form-group :label="$t('label.bankAccountNumber')">
                  <b-form-input v-model="bankAccountNumberUser"></b-form-input>
                </b-form-group>
              </div>
              <div class="col-12 col-md-3">
                <b-form-group
                  class="required-mess"
                  :label="$t('label.company')"
                >
                  <select class="form-control" :disabled="true">
                    <option v-bind:value="companyId">
                      {{ companyUser }}
                    </option>
                  </select>
                </b-form-group>
              </div>
              <div class="col-12 col-md-3">
                <b-form-group class="required-mess" :label="$t('label.branch')">
                  <select class="form-control" :disabled="true">
                    <option v-bind:value="branchId">
                      {{ branchName }}
                    </option>
                  </select>
                </b-form-group>
              </div>
              <div class="col-12 col-md-3">
                <b-form-group :label="$t('label.department')">
                  <select class="form-control" :disabled="true">
                    <option v-bind:value="departmentId">
                      {{ departmentUser }}
                    </option>
                  </select>
                </b-form-group>
              </div>
            </div>
          </b-collapse>
        </div>

        <div class="information-user-collapse">
          <div v-b-toggle:my-collapse2 class="information-user-collapse__title">
            <span class="when-open"
              ><font-awesome-icon icon="caret-down" /></span
            ><span class="when-closed"
              ><font-awesome-icon icon="caret-right"
            /></span>
            {{ $t("label.personalImage") }}
          </div>
          <b-collapse
            id="my-collapse2"
            visible
            class="information-user-collapse__body"
          >
            <div class="row">
              <div id="preview">
                <img
                  style="
                    height: 100px;
                    margin: 15px;
                    border-radius: 5px;
                    width: 100px;
                  "
                  v-if="newAvatar"
                  :src="newAvatar"
                />
                <img
                  style="
                    height: 100px;
                    margin: 15px;
                    border-radius: 5px;
                    width: 100px;
                  "
                  v-else="avatarUser"
                  :src="avatarUser"
                />
              </div>
              <div class="upload-images">
                <label for="insurance_policy" class="upload-images__hidden">
                  <input
                    type="file"
                    style="
                      display: block;
                      height: 100px !important;
                      width: 100px !important;
                    "
                    name="insurance_policy"
                    multiple="multiple"
                    required
                    class="upload-file"
                    id="insurance_policy"
                    accept=".png, .jpg, .jpeg, .pdf"
                    v-on:change="updateAvatarUser($event.target.files)"
                  />
                </label>

                <div class="upload-images--show">
                  <div class="text-center">
                    <font-awesome-icon icon="plus" />
                    <p>{{ $t("label.upload") }}</p>
                  </div>
                </div>
              </div>
              <div class="error-message w-100 clear text-left">
                {{
                  msg["avatar"] && msg["avatar"][0] ? $t(msg["avatar"][0]) : ""
                }}
              </div>
            </div>
          </b-collapse>
        </div>
        <div class="submit-form mt-4 mb-4 text-center">
          <button
            type="submit"
            style="background: #fb6340; color: #fff"
            @click="updateProfile()"
            class="btn"
          >
            {{ $t("label.update") }}
          </button>
          <button
            type="submit"
            style="
              background: #425993;
              color: rgb(255, 255, 255);
              border-color: #425993;
            "
            @click="modalChangePassword()"
            class="btn btn-warning"
          >
            {{ $t("label.changePassword") }}
          </button>
        </div>
      </div>
      <b-modal
        v-model="modelChangePassword"
        ref="modal-change-password"
        :title="$t('label.changePassword')"
        header-bg-variant="secondary"
        hide-footer
        centered
        size="md"
      >
        <div class="form-group">
          <div class="mb-1">{{ $t("label.oldPassword") }}</div>
          <input
            type="password"
            v-model="infoChangPassword.oldPassword"
            class="form-control"
            autocomplete="off"
          />
          <div class="error-message w-100 clear text-left">
            {{ msg["oldPassword"] ? $t(msg["oldPassword"]) : "" }}
          </div>
        </div>
        <div class="form-group">
          <div class="mb-1">{{ $t("label.password") }}</div>
          <input
            type="password"
            v-model="infoChangPassword.password"
            class="form-control"
            autocomplete="off"
          />
          <div class="error-message w-100 clear text-left">
            {{ msg["password"] ? $t(msg["password"]) : "" }}
          </div>
        </div>
        <div class="form-group">
          <div class="mb-1">{{ $t("label.confirmPassword") }}</div>
          <input
            type="password"
            v-model="infoChangPassword.confirmPassword"
            class="form-control"
            autocomplete="off"
          />
          <div class="error-message w-100 clear text-left">
            {{ msg["confirmPassword"] ? $t(msg["confirmPassword"]) : "" }}
          </div>
        </div>
        <div class="d-block text-center">
          <div class="text-center">
            <b-button class="btn btn-danger" @click="eChangePassword()"
              >{{ $t("label.save") }}
            </b-button>
            <b-button class="btn btn-default" @click="hideModalChangePassword"
              >{{ $t("label.cancel") }}
            </b-button>
          </div>
        </div>
      </b-modal>
      <!-- modal add  -->
    </div>
  </div>
</template>
<script>
import apiServer from "@/axiosServer";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import FlatpickrLanguages from "flatpickr/dist/l10n";
import moment from "moment";
export default {
  components: {
    flatPickr,
  },
  data() {
    return {
      isSupperAdmin: false,
      modelChangePassword: false,
      idUser: this.$route.params.userId,
      aliasID: "",
      idCheckIn: "",
      nameUser: "",
      avatarUser: "",
      avatarUserUpdate: "",
      newAvatar: "",
      emailUser: "",
      phoneNumberUser: "",
      companyUser: "",
      companyId: null,
      departmentUser: "",
      branchId: null,
      branchName: "",
      departmentId: null,
      genderUser: "0",
      genders: [
        { text: this.$t("label.male"), value: "1" },
        { text: this.$t("label.female"), value: "0" },
      ],
      addressUser: "",
      dateOfBirthUser: "",
      configDateTimePicker: {
        dateFormat: "d/m/Y",
        maxDate: "today",
        locale: FlatpickrLanguages["en"],
        shorthandCurrentMonth: true,
      },
      idCardUser: "",
      bankAccountNumberUser: "",
      msg: [],
      isInProgress: false,
      userName: "",
      infoChangPassword: [],
    };
  },
  mounted() {
    this.getProfile();
  },
  methods: {
    async getProfile() {
      let res;
      try {
        this.isInProgress = true;
        res = await apiServer.get("/api/v1/user/profile", {});
      } catch (error) {
      } finally {
        this.isInProgress = false;
      }
      if (res && res.data) {
        if (res.status === 200) {
          this.aliasID = res.data.aliasID;
          this.avatarUser = res.data.avatar;
          this.nameUser = res.data.fullName;
          this.idCheckIn = res.data.personID;
          this.branchName = res.data.branch_name;
          this.branchId = res.data.branch_id;
          this.emailUser = res.data.email;
          this.phoneNumberUser = res.data.phone;
          this.companyUser = res.data.company;
          this.companyId = res.data.companyId;
          this.departmentUser = res.data.department_name;
          this.departmentId = res.data.department_id;
          this.genderUser = res.data.gender;
          this.idCardUser = res.data.cardID;
          this.dateOfBirthUser = res.data.date_of_birth;
          this.addressUser = res.data.address;
          this.bankAccountNumberUser = res.data.bankNumber;
          this.userName = res.data.username;
        }
      }
    },
    async updateProfile() {
      this.msg = [];
      let res;
      try {
        this.isInProgress = true;
        const config = {
          header: {
            "Content-Type": "multipart/form-data",
          },
          // timeout: 0,
        };
        let dob =
          this.dateOfBirthUser != undefined && this.dateOfBirthUser != ""
            ? this.convertTimestampSendDate(this.dateOfBirthUser)
            : null;
        const formData = new FormData();
        formData.append("aliasID", this.aliasID);
        formData.append("file", this.avatarUserUpdate);
        formData.append("fullName", this.nameUser);
        formData.append("dateBirthDay", dob);
        formData.append("gender", this.genderUser);
        formData.append("cardID", this.idCardUser);
        formData.append("address", this.addressUser);
        formData.append("phone", this.phoneNumberUser);
        formData.append("email", this.emailUser);
        // formData.append("department", this.departmentUser);
        formData.append("bankNumber", this.bankAccountNumberUser);
        res = await apiServer.post(
          "api/v1/user/update-profile",
          formData,
          config
        );
      } catch (error) {
        if (error.response.status === 400) {
          this.msg = error.response.data;
          if (this.msg["message"] != undefined) {
            this.$notify({
              group: "bottomnotification",
              title: this.$t("label.notificationError"),
              text: this.$t(this.msg["message"]),
              type: "error",
            });
          }
        }
      } finally {
        this.isInProgress = false;
      }
      if (res && res.data) {
        if (res.status === 200) {
          this.$notify({
            group: "bottomnotification",
            title: this.$t("label.notification"),
            text: this.$t(res.data),
          });
          this.getProfile();
        }
      }
    },
    async eChangePassword() {
      let res;
      try {
        res = await apiServer.post("/api/v1/user/change-password", {
          userName: this.userName,
          oldPassword: this.infoChangPassword.oldPassword,
          password: this.infoChangPassword.password,
          confirmPassword: this.infoChangPassword.confirmPassword,
        });
      } catch (error) {
        if (error.response.status === 400) {
          this.msg = error.response.data;
        } else if (error.response.status === 401) {
          alert(this.$t(error.response.data));
        }
      }
      if (res && res.data) {
        if (res.status === 200) {
          this.modelChangePassword = false;
          this.$notify({
            group: "bottomnotification",
            title: this.$t("label.notification"),
            text: this.$t(res.data),
            position: "bottom-center",
          });
        }
      }
    },

    modalChangePassword() {
      (this.modelChangePassword = true),
        (this.msg = []),
        (this.infoChangPassword = []);
    },

    async hideModalChangePassword() {
      this.$refs["modal-change-password"].hide();
    },

    updateAvatarUser(file) {
      this.avatarUserUpdate = file[0];
      this.newAvatar = URL.createObjectURL(file[0]);
    },
    convertTimestampSendDate(date) {
      const dateString = date;
      const changedDate = dateString.replace(/(..)\/(..)\/(....)/, "$3-$2-$1");
      var date = new Date(changedDate);
      return moment(date).format("DD-MM-yyyy");
    },
  },
};
</script>
<style scoped lang="scss">
.info-user {
  .info-user__image {
    span {
      background-color: #fafafa;
      border: 1px dashed #d9d9d9;
      padding: 10px;
      display: inline-block;
      margin: 20px 0;
      border-radius: 5px;
    }
  }
  .info-user-desc {
    display: flex;
    align-items: center;
    .info-user-desc--more-info {
      ul {
        padding: 0;
        margin: 0;
        width: 100%;
      }
    }
  }
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
.information-user-collapse {
  border-bottom: 1px solid #ddd;
  .information-user-collapse__title {
    outline: none;
    cursor: pointer;
    padding: 10px;
    svg {
      margin-right: 10px;
    }
  }
  .information-user-collapse__body {
    padding: 10px;
    .col-form-label {
      font-weight: bold;
    }
    .image-avatar-user {
      background-color: #fafafa;
      border: 1px dashed #d9d9d9;
      padding: 10px;
      display: inline-block;
      margin: 20px 0;
      border-radius: 5px;
    }
  }
}
.upload-images {
  position: relative;
  display: inline-block;
  margin: 15px 0 30px;
  cursor: pointer;
  .upload-images__hidden {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 99;
    .custom-file {
      height: 100%;
      .custom-file-label {
        height: 100%;
      }
    }
  }
  .upload-images--show {
    height: 100px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    background: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    p {
      margin: 0;
    }
  }
  &:hover {
    .upload-images--show {
      border: 1px dashed #fb6340;
    }
  }
}
@media screen and (max-width: 767px) {
  .submit-form {
    position: fixed;
    bottom: 0;
    background: #fff;
    width: 100vw;
    left: 0;
    z-index: 99;
    margin: 0 !important;
    padding: 10px 2%;
    button {
      width: 45%;
    }
  }
  .info-user__image span {
    height: 200px;
  }
}
</style>
<style lang="scss">
.information-user-collapse__body {
  .col-form-label {
    font-weight: bold;
  }
}
.modal-header {
  .close {
    padding: 0.6rem 1rem !important;
    margin: -1rem -1rem -1rem auto !important;
  }
}
.custom-file-label {
  height: 100%;
  cursor: pointer;
}
.form-control[readonly] {
  background: #fff !important;
}
.info-user__image {
  span {
    height: 300px;
    position: relative;
    overflow: hidden;
  }
  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}
.changeAvatar {
  top: 20px;
  left: 255px;
}
.upload-images {
  height: 14px;
}
@media screen and (max-width: 767px) {
  #main-default.container-fluid,
  #main-default.container-sm,
  #main-default.container-md,
  #main-default.container-lg,
  #main-default.container-xl {
    padding: 15px !important;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>

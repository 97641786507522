<template>
    <div class="row">
        <div class="card-body m-0 p-0">
            <div style="display: flex;">
                <div style="margin-left: 10%; text-align: center;">
                    <h3>CÔNG TY CỔ PHẦN CAO SU ĐÀ NẴNG</h3>
                    <h3>ĐV: {{ departmentName }}</h3>
                </div>
                <div>
                </div>
            </div>

            <div class="table-responsive" style="overflow-x: auto;">
                <table id="tbl-preview" class="table b-table table-striped table-hover table-bordered">
                    <tr v-for="(row, i) in previewData" :key="i"
                        :class="getClassForRow(row)">
                        <td v-if="i === 0" :colspan="row.length">{{ row[1] }}</td>
                        <template v-else>
                            <td v-for="(cell, j) in row" v-if="j > 0" :key="j" class="max-width-column">{{ cell }}</td>
                        </template>
                    </tr>
                </table>
                <div style="display: flex; margin-left: 20%">
                    <div>   
                        <h3>PHÒNG NHÂN SỰ TIỀN LƯƠNG</h3>
                    </div>
                    <div style="margin-left: 40%; text-align: center;">
                        <span>Đà Nẵng, ngày .... tháng .... năm ....</span>
                        <h3>NGƯỜI KIỂM TRA</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        previewData: {
            type: [Array, Object]
        },
        departmentName: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            maNVCells: [],
            hoTenCells: []
        };
    },
    methods: {
        getClassForRow(row) {
            if (this.hasHoTen(row) || this.hasMaNV(row)) {
                return { 'no-border': true };
            }
            if (this.hasNgayTinhCong(row)) {
                return { 'bold-text': true };
            }
            return {};
        },
        hasMaNV(row) {
            if (!row) return false;
            return row.some(cell => cell && cell.includes && cell.includes('Mã NV'));
        },
        hasHoTen(row) {
            if (!row) return false;
            return row.some(cell => cell && cell.includes && cell.includes('Họ và tên'));
        },
        hasNgayTinhCong(row) {
            if (!row) return false;
            return row.some(cell => cell && cell.includes && cell.includes('Ngày tính công'));
        },
    },
}
</script>
<style scoped>
#tbl-preview {
    border: none;
}

#tbl-preview tr:first-child td {
    text-align: center;
    font-weight: bold;
    border: none;
    font-size: 20px;
}

#tbl-preview tr.tbl-footer {
    border: none;
}

#tbl-preview tr.tbl-footer td {
    border: none;
}

.no-border td {
    border: none;
}

.bold-text {
    font-weight: bold;
}
</style>
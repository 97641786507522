<template>
  <!-- Page content -->
  <div id="main-default" class="container-fluid">
    <div class="card col-xl-12">
      <div class="row">
        <div class="col-xl-12">
          <h1 class="page-title mt-2">
            {{ $t("menu.attendanceData") }}
          </h1>
          <p>
            {{ $t("label.totalRecord") }}:
            {{ dataTable?.length }}
          </p>
          <p>
            {{ $t("label.totalEmployee") }}:
            {{ dataTable?.length }}
          </p>
        </div>
        <div class="col-xl-12">
          <div class="row">
            <div class="col-xs-12 col-lg-3 col-md-4 mt-3">
              <flat-pickr
                v-model="dateRange"
                :config="configDateTimePicker"
                :placeholder="$t('label.selectDateRange')"
                name="date_apply"
                class="form-control"
              ></flat-pickr>
            </div>
            <div class="col-xs-12 col-lg-3 col-md-4 mt-3">
              <input
                type="text"
                v-model="usercode"
                :placeholder="$t('label.employeeCode')"
                class="form-control"
              />
            </div>
            <div class="col-xs-12 col-lg-3 col-md-4 mt-3">
              <input
                type="text"
                v-model="username"
                :placeholder="$t('label.staffsName')"
                class="form-control"
              />
            </div>
            <div
              class="col-xs-12 col-lg-3 col-md-4 mt-3"
              v-if="isSupperAdmin || isComAdmin"
            >
              <!-- <select v-model="branch" class="form-control">
                <option v-for="branch in branchs" v-bind:value="branch.value">
                  {{ branch.text }}
                </option>
              </select> -->
            <treeselect v-model="departmentId" :options="departmentOption"
              :default-expand-level="Infinity" :noChildrenText="$t('label.noNodeChildren')"
              :placeholder="$t('label.chooseDepartment')" />
            </div>
            <div class="col-xs-12 col-lg-3 col-md-4 mt-3">
              <treeselect
                v-model="departmentid"
                :options="departmentTree"
                :default-expand-level="1"
                :placeholder="$t('label.chooseDepartment')"
              />
            </div>
            <div class="col-xs-12 col-lg-3 col-md-4 mt-3">
              <treeselect
                v-model="positionid"
                :multiple="true"
                :options="positionTree"
                :flat="true"
                :default-expand-level="1"
                :placeholder="$t('label.choosePosition')"
              />
            </div>
            <div class="col-xs-12 col-lg-3 col-md-4 mt-3">
              <b-form-checkbox v-model="lateEarly">{{
                $t("label.late") + "/" + $t("label.comeBackBefore")
              }}</b-form-checkbox>
            </div>
            <div class="col-xs-12 col-lg-3 col-md-4 mt-3">
              <b-button
                variant="btn btn-info  btn-outline-primary w-100 font-weight-bold"
                @click="search()"
                >{{ $t("label.search") }}
              </b-button>
            </div>
          </div>
        </div>
        <div class="col-xl-12 mt-4">
          <div class="row mt-3">
            <div class="col-12 text-right">
              <div class="mb-2">
                <a
                  href="javascript:void(0)"
                  @click="showModalAddCheckin()"
                  class="btn btn-danger bg-gradient-danger"
                >
                  <font-awesome-icon icon="plus" />
                  {{ $t("label.addNew") }}</a
                >
              </div>
            </div>
            <div class="col-xl-12 col-xs-12 text-right mb-2">
              <button
                class="btn btn-primary mr-2"
                @click="downloadFileTemplate()"
              >
                <font-awesome-icon icon="download" />
                {{ $t("label.downloadFileTemplate") }}
              </button>
              <b-form-file
                style="width: auto; text-align: left;"
                :placeholder="$t('label.importCheckinFromFile')"
                drop-placeholder="Drop file here..."
                accept=".xlsx, .xls, .dat"
                ref="file"
              ></b-form-file>
              <b-button
                variant="btn btn-primary ml-2"
                @click="importCheckin()"
                >{{$t('label.import')}}
              </b-button>
            </div>
            <div class="col-xl-12">
              <div class="card">
                <div class="card-body m-0 p-0 table-responsive">
                  <b-table
                    striped
                    bordered
                    class="default-table"
                    id="table-checkin"
                    hover
                    :items="dataTable"
                    :fields="fields"
                    responsive
                    show-empty
                  >
                    <template #cell(avatar)="data">
                      <a
                        href="javascript:void(0)"
                        @click="showImage(data.item.avatar)"
                      >
                      <img
                        v-if="data.item.avatar"
                        width="95px"
                        height="95px"
                        :src="data.item.avatar"
                        :alt="data.item.personName"
                    />
                    <img
                        v-else
                        width="95px"
                        height="95px"
                        src="../../assets/img/avtDefault.jpg"
                        :alt="data.item.personName"
                    />
                      </a>
                    </template>
                    <template #cell(dateOfEntry)="data">
                      {{ convertTimestampToDate(data.item.dateID) }}
                    </template>
                    <template #cell(name)="data">
                      <ul class="list-unstyled m-0">
                        <li>
                          <strong>{{ data.item.fullName }}</strong>
                        </li>
                        <li class="small">
                          {{ $t("label.idEmployee") }}: {{ data.item.aliasID }}
                        </li>
                        <li class="small">
                          {{ $t("label.department") }}:
                          {{ data.item.department }}
                        </li>
                        <li class="small">
                          {{ $t("label.jobPosition") }}:
                          {{ data.item.position }}
                        </li>
                      </ul>
                    </template>
                    <template #cell(shift)="data">
                      <span
                        class="bold"
                        v-if="data.item.default_shift == true"
                        >{{ $t("label.defaultShift") }}</span
                      >
                      {{ data.item.shifts_name }}
                    </template>
                    <template #cell(toWork)="data">
                      {{ convertOnlyTime(data.item.checkin) }}
                    </template>
                    <template #cell(endWork)="data">
                      <template v-if="data.item.checkout != null">
                        {{ convertOnlyTime(data.item.checkout) }}
                      </template>
                      <template v-else>
                        <span> </span>
                      </template>
                    </template>
                    <template #cell(minuteLate)="data">
                      {{ data.item.minute_late }}
                    </template>
                    <template #cell(minuteEarly)="data">
                      {{ data.item.minute_out_early }}
                    </template>
                    <template #cell(function)="data">
                      <button
                        class="btn btn-warning btn-sm"
                        @click="editCheckin(data.item)"
                        v-b-tooltip.hover
                        :title="$t('label.edit')"
                      >
                        <font-awesome-icon icon="edit" />
                      </button>
                    </template>
                    <template #empty="scope">
                      {{ $t("notify.message.nodata") }}
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRecord"
                    :per-page="perPage"
                    align="right"
                    size="sm"
                    class="m-4"
                    aria-controls="table-checkin"
                    v-on:click.native="search()"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-modal
        v-model="modalImage"
        hide-footer
        centered
        hide-header
        modal-class="modal-image"
        size="lg"
      >
        <div class="d-block text-center">
          <img :src="urlImage" />
        </div>
      </b-modal>
      <b-modal
        v-model="modalUpdateCheckin"
        :title="$t('label.addEdit') + ' ' + $t('label.checkin')"
        ref="modal-update-checkin"
        header-bg-variant="secondary"
        hide-footer
        centered
        modal-class="modal-image"
        size="lg"
      >
        <b-form ref="form" class="row">
          <div class="col-lg-6 col-md-6 col-xs-12">
            <b-form-group
              :label="$t('label.employeeCode') + ' - ' + $t('label.fullName')"
            >
              <div class="autocomplete" v-click-outside="onClickOutside">
                <b-form-input
                  v-model="employee"
                  @input="onChangeCodeName"
                  type="text"
                >
                </b-form-input>
                <ul v-show="isOpenCodeNameRes" class="autocomplete-results">
                  <li
                    v-for="(result, i) in codeNameResults"
                    :key="i"
                    @click="setCodeNameResult(result)"
                    class="autocomplete-result"
                  >
                    {{ result.value }} - {{ result.text }}
                  </li>
                </ul>
              </div>
              <div class="error-message w-100 clear text-left">
                {{ msg["alias"] ? $t(msg["alias"]) : "" }}
              </div>
            </b-form-group>
          </div>
          <div class="col-lg-6 col-md-6 col-xs-12">
            <b-form-group :label="$t('label.shift')">
              <select v-model="shiftId" class="form-control">
                <option v-for="shift in shifts" v-bind:value="shift.value">
                  {{ shift.text }}
                </option>
              </select>
            
              <div class="error-message w-100 clear text-left">
                {{ this.msg["shiftId"] ? $t(this.msg["shiftId"]) : "" }}
              </div>
            </b-form-group>
          </div>
          <div class="col-lg-6 col-md-6 col-xs-12">
            <b-form-group :label="$t('label.dateOfEntry')">
              <flat-pickr
                v-model="dateID"
                :config="configDateIDPicker"
                :placeholder="$t('label.chooseDate')"
                name="dateID"
                class="form-control"
              ></flat-pickr>
              <div class="error-message w-100 clear text-left">
                {{ msg["date"] ? $t(msg["date"]) : "" }}
              </div>
            </b-form-group>
          </div>
          <div class="col-lg-6 col-md-6 col-xs-12">
            <b-form-group :label="$t('label.toWork')">
              <flat-pickr
                v-model="startWork"
                :config="configDateTimePicker1"
                :placeholder="$t('label.chooseTime')"
                name="startWork"
                class="form-control"
              ></flat-pickr>
              <div class="error-message w-100 clear text-left">
                {{ msg["checkin"] ? $t(msg["checkin"]) : "" }}
              </div>
            </b-form-group>
          </div>
          <div class="col-lg-6 col-md-6 col-xs-12">
            <b-form-group :label="$t('label.endWork')">
              <flat-pickr
                v-model="endWork"
                :config="configDateTimePicker1"
                :placeholder="$t('label.chooseTime')"
                name="endWork"
                class="form-control"
              ></flat-pickr>
              <div class="error-message w-100 clear text-left">
                {{ msg["checkout"] ? $t(msg["checkout"]) : "" }}
              </div>
            </b-form-group>
          </div>
          <div>
            <input type="hidden" name="id" :value="id" />
            <input type="hidden" name="personID" :value="personID" />
            <input type="hidden" name="avatar" :value="imgUrl" />
          </div>
          <div class="col-12 mt-3 text-center">
            <b-button class="btn btn-danger" @click="saveCheckin()">{{
              $t("label.save")
            }}</b-button>
            <b-button class="btn btn-default" @click="hideModalCheckin">{{
              $t("label.cancel")
            }}</b-button>
          </div>
        </b-form>
      </b-modal>
    </div>
    <pulse-loader :loading="loading"></pulse-loader>
  </div>
</template>
<script>
import apiClient from "@/axios";
import apiServer from "@/axiosServer";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import FlatpickrLanguages from "flatpickr/dist/l10n";
import Treeselect from "@riophae/vue-treeselect";
import PulseLoader from "@/components/PulseLoader.vue";
import moment from "moment";
import Vue from "vue";

Vue.directive("click-outside", {
  bind (el, binding, vnode) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind (el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

export default {
  components: {
    flatPickr,
    Treeselect,
    PulseLoader,
  },
  data () {
    return {
      isSupperAdmin: false,
      isComAdmin: false,
      loading: false,
      date: this.getNowDate(),
      configDateTimePicker: {
        dateFormat: "d/m/Y",
        mode: "range",
        maxDate: "today",
        locale: FlatpickrLanguages["en"],
        shorthandCurrentMonth: true,
      },
      configDateIDPicker: {
        dateFormat: "d/m/Y",
        locale: FlatpickrLanguages["en"],
        defaultDate: "today",
        maxDate: "today",
        shorthandCurrentMonth: true,
      },
      configDateTimePicker1: {
        enableTime: true,
        dateFormat: "d/m/Y H:i:ss",
        defaultDate: this.dateID,
        time_24hr: true,
        locale: FlatpickrLanguages["en " ],
        noCalendar: true,
        shorthandCurrentMonth: true,
      },
      urlImage: "",
      modalImage: false,
      accessToken: process.env.VUE_APP_TOKEN_HANET,
      placeId: "",
      branch: undefined,
      branchs: [],
      departmentid: null,
      departmentOption: [],
      departmentTree: [],
      positionTree: [],
      username: "",
      // dateRange: this.getNowDate(),
      dateRange: '',
      from_date: null,
      to_date: null,
      usercode: null,
      departmentid: null,
      positionid: null,
      lateEarly: null,
      msg: [],
      dataTable: [],
      totalRecord: 0,
      currentPage: 1,
      perPage: 25,
      fields: [
        {
          label: this.$t("label.image"),
          key: "avatar",
          tdClass: "text-center",
          thClass: "text-center",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t("label.dateOfEntry"),
          key: "dateOfEntry",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t("label.fullName"),
          key: "name",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t("label.sessionWork"),
          key: "shift",
          tdClass: "text-center",
          class: "align-middle",
          thClass: "text-center",
          sortable: true,
        },
        {
          label: this.$t("label.toWork"),
          key: "toWork",
          tdClass: "text-center",
          class: "align-middle",
          thClass: "text-center",
          sortable: true,
        },
        {
          label: this.$t("label.endWork"),
          key: 'endWork',
          tdClass: "text-center",
          class: "align-middle",
          thClass: "text-center",
          sortable: true,
        },
        {
          label: this.$t("label.minuteLate"),
          key: "minuteLate",
          tdClass: "text-center",
          class: "align-middle",
          thClass: "text-center",
          sortable: true,
        },
        {
          label: this.$t("label.minuteEarly"),
          key: "minuteEarly",
          class: "align-middle",
          tdClass: "text-center",
          thClass: "text-center",
          sortable: true,
        },
        {
          label: "",
          key: "function",
          class: "align-middle text-center",
          sortable: true,
        },
      ],
      modalUpdateCheckin: false,
      id: null,
      employee: null,
      shiftId: null,
      shifts: [],
      dateID: null,
      imgUrl: null,
      startWork: null,
      endWork: null,
      personID: null,
      isOpenCodeNameRes: false,
      codeNameResults: [],
    };
  },
  mounted () {
    this.getListPersonByPlace();
    if (this.$store.getters.superAdmin == true) {
      this.isSupperAdmin = true;
    }
    if (this.$store.getters.comAdmin == true) {
      this.isComAdmin = true;
    }
    if (this.isSupperAdmin || this.isComAdmin) {
      this.getListBranch();
    }
    this.getDepartmentTree();
    this.getPositionTree();
    this.getListShifts();
    if (this.$store.getters.locale == "vi") {
      this.configDateTimePicker.locale = FlatpickrLanguages["vn"];
    }
  },
  methods: {
    changeDepartment (){
      setTimeout(() => {this.getListBranch()}, 500);
    },
    getNowDate () {
      let today = new Date();
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0");
      const yyyy = today.getFullYear();
      today = dd + "/" + mm + "/" + yyyy;
      return today;
    },
    search () {
      if (this.departmentid && this.departmentid.length == 0) {
        this.departmentid = null;
      }
      if (this.positionid && this.positionid.length == 0) {
        this.positionid = null;
      }
      this.getListPersonByPlace();
      this.date = this.dateRange;
    },
    async getListBranch () {
      let res;
      try {
        res = await apiServer.post("/api/v1/company/get-all-branch", {});
        if (res && res.data) {
          this.branchs.push({
            value: undefined,
            text: this.$t("label.allBranches"),
          });
          for (let br of res.data) {
            this.branchs.push({ value: br.placeID, text: br.branch_name });
          }
          this.date = this.dateRange;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getDepartmentTree () {
      let res;
      try {
        res = await apiServer.post("api/v1/department/list", {});
      } catch (error) {}
      if (res && res.data) {
        this.departmentTree = res.data;
      }
    },
    async getPositionTree () {
      let res;
      try {
        res = await apiServer.get("api/v1/position/tree", {});
      } catch (error) {}
      if (res && res.data) {
        this.positionTree = [res.data];
      }
    },
    async getListShifts () {
      this.shifts = [];
      let res;
      try {
        res = await apiServer.post("api/v1/shifts/get", {
          shifts_name: this.shiftsName,
        });
      } catch (error) {}
      if (res && res.data) {
        if (res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            this.shifts.push({
              value: res.data[i].shift_id,
              text: res.data[i].shifts_name,
            });
          }
        }
      }
    },
    convertDateTime (date) {
      if (date == undefined || date == null) return null;
      return moment(date).format("DD/MM/yyyy HH:mm:ss");
    },
    convertOnlyTime (date) {
      if (date == undefined || date == null) return null;
      return moment(date).format("HH:mm:ss");
    },
    convertDate (inputDate) {
      if (inputDate == undefined || inputDate == null) return "";
      let date = new Date(inputDate);
      const dd = String(date.getDate()).padStart(2, "0");
      const mm = String(date.getMonth() + 1).padStart(2, "0");
      const yyyy = date.getFullYear();
      return yyyy + "-" + mm + "-" + dd;
    },
    showImage (urlImage) {
      this.urlImage = urlImage;
      this.modalImage = true;
    },
    async getListPersonByPlace () {
      this.loading = true;
      this.from_date = null;
      this.to_date = null;
      if (this.dateRange !== "") {
        let dateArr = this.dateRange.split(" ");

        this.from_date = this.convertTimestampSendDate(dateArr[0]);
        if (dateArr.length == 1) {
          this.to_date = this.from_date;
        } else {
          this.to_date = this.convertTimestampSendDate(dateArr[2]);
        }
      }
      // if (this.dateRange !== null && this.dateRange !== '') {
      //   let dateArr = this.dateRange.split(' ');
      //   timeEnd = this.convertTimestampSendDate(dateArr[0]);
      //   if (dateArr.length == 1) {
      //     timeStart = timeEnd;
      //   }
      //   else {
      //     timeStart = this.convertTimestampSendDate(dateArr[2]);
      //   }

      // }
      let user_code = this.usercode != null ? this.usercode.trim() : null;
      let user_name = this.username != null ? this.username.trim() : null;
      let res;
      // eslint-disable-next-line no-useless-catch
      try {
        res = await apiServer.post("/api/v1/camera/get-check-in", {
          fromdate: this.from_date,
          todate: this.to_date,
          usercode: user_code,
          username: user_name,
          placeid: this.branch,
          departmentid: this.departmentid,
          positionid: this.positionid,
          islate: this.lateEarly,
          currentPage: this.currentPage,
          rowPerPage: this.perPage,
        });
      } catch (error) {
        this.loading = false;
        throw error;
      }
      if (res && res.data) {
        this.dataTable = res.data.data.items;
        this.totalRecord = res.data.data.totalRecord;
        this.loading = false;
      }
    },
    async onChangeCodeName () {
      this.isOpenCodeNameRes = true;
      let res;
      try {
        res = await apiServer.post("api/v1/user/suggest-employee", {
          placeID: this.$store.getters.placeID,
          keyword: this.employee,
        });
      } catch (error) {}
      if (res && res.data) {
        this.codeNameResults = [];
        if (res.data.length > 0) {
          for (let user of res.data) {
            this.codeNameResults.push({
              value: user.aliasID,
              text: user.fullName,
              personID: user.personID,
              avatar: user.avatar,
            });
          }
        }
      }
    },
    async saveCheckin () {
      let arrCodeName =
        this.employee != null ? this.employee.split(" - ") : [null];
      let res;
      this.loading = true;
      try {
        res = await apiServer.post("/api/v1/checkin/save", {
          id: this.id,
          dateID: this.convertTimestampSendDate(this.dateID),
          personID: this.personID,
          aliasID: arrCodeName[0],
          avatar_image_url: this.imgUrl,
          shiftId: this.shiftId,
          checkin: this.convertStringToDateTime(this.startWork),
          checkout: this.convertStringToDateTime(this.endWork),
        });
        if (res && res.data) {
          this.loading = false;
          this.modalUpdateCheckin = false;
          this.$notify({
            group: "bottomnotification",
            title: this.$t("label.notification"),
            text: this.$t(res.data.message),
            position: "bottom-center",
          });
          this.getListPersonByPlace();
        }
      } catch (error) {
        this.loading = false;
        if (error.response.status === 400) {
          this.msg = error.response.data;
        }
        if (error.response.data.message) {
          this.$notify({
            group: "bottomnotification",
            title: this.$t("label.notificationError"),
            text: this.$t(error.response.data.message),
            type: "error",
            position: "bottom-center",
          });
        }
      }
    },
    editCheckin (item) {
      this.id = item.id;
      this.shiftId = item.shiftId;
      this.employee = item.aliasID + " - " + item.fullName;
      this.dateID = this.convertTimestampToDate(item.dateID);
      this.startWork = this.convertDateTimeToString(item.checkin);
      this.endWork = this.convertDateTimeToString(item.checkout);
      this.imgUrl = item.avatar;
      this.modalUpdateCheckin = true;
      this.personID = item.personID;
    },
    async downloadFileTemplate () {
      let res;
      try {
        res = await apiServer
          .get("/api/v1/checkin/downloadTemplate", {
            responseType: "blob",
          })
          .then((response) => {
            const url = URL.createObjectURL(
              new Blob([response.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "checkin");
            document.body.appendChild(link);
            link.click();
          });
      } catch (error) {
        console.log(error);
      }
    },
    async importCheckin () {
      let file = this.$refs.file.files[0];
      if (file == null) {
        this.$notify({
          group: "bottomnotification",
          title: this.$t("label.notificationError"),
          text: this.$t("error.message.noSelectFile"),
          type: "error",
          position: "bottom-center",
        });
        return false;
      }
      if (this.isSupperAdmin || this.isComAdmin) {
        this.modalImport = true;
      } else {
        await this.confirmImportCheckin();
      }
    },
    async confirmImportCheckin () {
      let file = this.$refs.file.files[0];
      const filename = file.name;
      const fileExt = filename.substring(filename.lastIndexOf('.'), filename.length)
      let importEndpoint = "api/v1/checkin/import";
      if(fileExt === ".dat") {
        importEndpoint = "api/v1/checkin/import-finger";
      }
      let formData = new FormData();
      formData.append("file", file);
      // formData.append("placeId", this.placeId);
      // formData.append("companyId", this.company);
      // formData.append("file", files[0]);
      const config = {
        header: {
          "Content-Type": "multipart/form-data",
        },
      };
      let res;
      this.msg = [];
      try {
        this.loading = true;
        res = await apiServer.post(importEndpoint, formData, config);
      } catch (error) {
        this.loading = false;
        if(error.response.data.message) {
          this.$notify({
            group: 'bottomnotification',
            title: this.$t('label.notificationError'),
            text: this.$t(error.response.data.message),
            type: 'error',
            position:'bottom-center'
          });
        }
        await this.getListPersonByPlace();
      } finally {
        this.loading = false;
      }
      if (res && res.data) {
        this.$notify({
          group: "bottomnotification",
          title: this.$t("label.notification"),
          text: this.$t(res.data.message),
          position: "bottom-center",
        });
        await this.getListPersonByPlace();
      }
    },
    onClickOutside () {
      this.isOpenCodeNameRes = false;
    },
    showModalAddCheckin () {
      this.id = null;
      this.shiftId = null;
      this.employee = null;
      this.dateID = this.getNowDate();
      this.startWork = this.dateID + " 08:00:00";
      this.endWork = this.dateID + " 17:00:00";
      this.imgUrl = null;
      this.modalUpdateCheckin = true;
    },
    setCodeNameResult (result) {
      this.employee = result.value + " - " + result.text;
      this.personID = result.personID;
      this.imgUrl = result.avatar;
      this.isOpenCodeNameRes = false;
    },
    hideModalCheckin () {
      this.$refs["modal-update-checkin"].hide();
    },
    convertTimestampToDate (timestamp) {
      if (timestamp == null || timestamp == undefined) return null;
      return moment(timestamp).format("DD/MM/yyyy");
    },
    convertDateTimeToString (timestamp) {
      if (timestamp == null || timestamp == undefined) return null;
      return moment(timestamp).format("DD/MM/yyyy HH:mm:ss");
    },
    convertTimestampSendDate (date) {
      if (date == undefined || date == null) {
        return null;
      }
      const dateString = date;
      const changedDate = dateString.replace(/(..)\/(..)\/(....)/, "$3-$2-$1");
      var date = new Date(changedDate);
      return moment(date).format("yyyy-MM-DD");
    },
    convertStringToDateTime (date) {
      if (date == undefined || date == null) {
        return null;
      }
      const dateString = date;
      const changedDate = dateString.replace(
        /(..)\/(..)\/(....) (..):(..)/,
        "$3-$2-$1 $4:$5"
      );
      var date = new Date(changedDate);
      return moment(date.getTime()).format("yyyy-MM-DD HH:mm:ss");
    },
  },
  watch: {
    dateID (date) {
      this.startWork = date + " " + this.startWork.split(" ")[1];
      this.endWork = date + " " + this.endWork.split(" ")[1];
    },
    startWork (startTime) {
      if (!this.endWork || startTime > this.endWork) {
        this.endWork = startTime;
      }
    },
    endWork (endTime) {
      if (!this.startWork || endTime < this.startWork) {
        this.startWork = endTime;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.btn {
  font-weight: normal;
}

.filter-asc,
.filter-desc {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

.filter-asc {
  margin-bottom: 1px;
  border-bottom: 5px solid #ccc;
}

.img-profile {
  width: 60px !important;
  border-radius: 10px;
  border: 1px solid #dedede;
}

.table-small td {
  font-size: 11px !important;
  padding: 5px !important;
  vertical-align: middle !important;
}
.autocomplete {
  position: relative;
}

.autocomplete-results {
  position: absolute;
  z-index: 999;
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  min-height: 1em;
  max-height: 18em;
  overflow: auto;
  background: #fff;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 2px 4px;
  cursor: pointer;
}

.autocomplete-result:hover {
  background-color: #eeeceb;
}
</style>

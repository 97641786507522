<template>
  <div class="row">
    <div class="card-body m-0 p-0">
      <h2 style="margin-left: 4%; margin-top: 1%;">Công ty cổ phần cao su Đà Nẵng</h2>
      <div>
        <hr class="custom-hr" style="width: 7%; margin-left: 5%; border-color: black;">
        <h2>ĐV: {{ departmentName }}</h2>
        <h2>Tổ (bộ phận): {{ departmentUnit }}</h2>
      </div>
      <div class="table-responsive" style="overflow-x: auto;">
        <table id="tbl-preview" class="table b-table table-striped table-hover table-bordered">
          <tr v-for="(row, i) in previewData" :key="i" :class="i >= (previewData.length - 2) ? 'tbl-footer' : ''">
            <td v-if="i === 0 || i === 1" :colspan="row.length">{{ row[1] }}</td>
            <template v-else>
              <td v-for="(cell, j) in row" v-if="j > 0" :key="j" class="max-width-column" :rowspan="getRowspan(cell)"
                :colspan="getColspan(cell)">{{ cell }}</td>
            </template>
          </tr>
        </table>
      </div>
      <div>
        <span style="text-decoration: underline;">Các kí hiệu:</span>
        <div style="display: flex;">
          <table class="sign-table" style="margin-right: 5%; width: 7%;">
            <tr>
              <td>CT : </td>
              <td>Công tác</td>
            </tr>
            <tr>
              <td>F : </td>
              <td>Nghỉ phép</td>
            </tr>
            <tr>
              <td>Ô : </td>
              <td>Nghỉ ốm</td>
            </tr>
            <tr>
              <td>Kađ : </td>
              <td>Công ca đêm</td>
            </tr>
            <tr>
              <td>TS : </td>
              <td>Thai sản</td>
            </tr>
            <tr>
              <td>H : </td>
              <td>Học tập</td>
            </tr>
          </table>
          <table class="sign-table" style="margin-right: 35%; width: 12%;">
            <tr>
              <td>TQ : </td>
              <td>Quân sự</td>
            </tr>
            <tr>
              <td>NB : </td>
              <td>Nghỉ bù</td>
            </tr>
            <tr>
              <td>R : </td>
              <td>Việc riêng có lương</td>
            </tr>
            <tr>
              <td>Ro : </td>
              <td>Việc riêng không lương</td>
            </tr>
            <tr>
              <td>K : </td>
              <td>Nghỉ khác</td>
            </tr>
            <tr>
              <td>O : </td>
              <td>Không lý do</td>
            </tr>
          </table>
          <div style="display: flex; width: 60%;">
            <div>
              <h3>PHÒNG NHÂN SỰ TIỀN LƯƠNG</h3>
            </div>
            <div style="margin-left: 13%;">
              <h3>THỦ TRƯỞNG ĐƠN VỊ</h3>
            </div>
            <div style="margin-left: 13%; text-align: center;">
              <span>Đà Nẵng, ngày .... tháng .... năm ....</span>
              <h3>NGƯỜI KIỂM TRA</h3>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    previewData: {
      type: [Array, Object]
    },
    departmentName: {
      type: String,
      required: true
    },
    departmentUnit: {
      type: String,
      required: true
    },
    day: {
      type: Number,
      required: true
    },
    month: {
      type: String,
      required: true
    },
    year: {
      type: Number,
      required: true
    }
  },
  methods: {
    getClasses(cell, columnIndex, rowIndex) {
      if (cell && typeof cell === 'string' && (cell.includes('STT') || cell.includes('Mã NV') || cell.includes('Họ tên') || cell.includes('Tổng công cơm ca') || cell.includes('Hệ số') || cell.includes('HTNV'))) {
        return '';
      }
      return 'max-width-column';
    },
    getRowspan(cell, columnIndex, rowIndex) {
      if (cell && typeof cell === 'string' && (cell.includes('STT') || cell.includes('Mã NV') || cell.includes('Họ tên') || cell.includes('Tổng công cơm ca') || cell.includes('Hệ số') || cell.includes('HTNV'))) {
        return 2;
      }
      return 1;
    },
    getColspan(cell, rowIndex) {
      if (cell && typeof cell === 'string' && (cell === 'Máy chấm' || cell === 'Thực tế')) {
        return 2;
      } else if (cell && typeof cell === 'string' && cell === 'Ngày nghỉ') {
        return 5;
      }
      return 1;
    }
  }
}
</script>
<style scoped>
#tbl-preview {
  border: none;
}

#tbl-preview tr:first-child td {
  text-align: center;
  font-weight: bold;
  border: none;
  font-size: 22px;
}

#tbl-preview tr:nth-child(2) td {
  text-align: center;
  font-weight: bold;
  border: none;
}

#tbl-preview tr:nth-child(3) td {
  text-align: center;
  vertical-align: middle
}

#tbl-preview tr:last-child {
  font-weight: bold;
}
</style>
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import store from '@/store';
import Role from '@/role/role'
import AdminLayout from '@/layouts/AdminLayout.vue'
import LoginLayout from '@/views/Login.vue'
import EmployeeLayout from '@/layouts/EmployeeLayout.vue'
import HomePage from '@/views/Home.vue'
import Welcome from '@/views/Welcome.vue'
import Welcome2 from '@/views/Welcome2.vue'
import DownloadContact from '@/views/DownloadContact.vue'
import AdminDashboard from '@/views/admin/AdminDashboard.vue'
import EmployeeDashboard from '@/views/employee/Dashboard.vue'
import InOutHistory from '@/views/admin/InOutHistory.vue'
import EmployeeManager from '@/views/admin/EmployeeManager.vue'
import AttendanceManagement from '@/views/admin/AttendanceManagement.vue'
import ShowListShift from '@/views/admin/ShowListShift.vue'
import ArrangeWorkShift from '@/views/admin/ArrangeWorkShift.vue'
import StaffInShifts from '@/views/admin/StaffInShifts.vue'
import LeaveApplication from '@/views/admin/LeaveApplication.vue'
import BranchManagement from '@/views/admin/BranchManagement.vue'
import DepartmentManager from '@/views/admin/DepartmentManager.vue'
import EditUser from '@/views/admin/EditUser.vue'
import AddUser from '@/views/admin/AddUser.vue'
import CheckIn from '@/views/admin/CheckIn.vue'
import DayOff from '@/views/admin/DayOff.vue'
import ReportTimeKeep from '@/views/admin/ReportTimeKeep.vue'
import AttendanceReport from '@/views/admin/AttendanceReport.vue'
import ClaimTimeSheetList from '@/views/admin/ClaimTimeSheetList.vue'
import CompanyManager from '@/views/admin/CompanyManager.vue'
import VueSocketIO from 'vue-socket.io'
import msg from '@/i18n/index'
import CheckInEmployee from '@/views/employee/CheckIn.vue'
import InOutHistoryEmployee from '@/views/employee/InOutHistory.vue'
import Personnel from '@/views/employee/Personnel.vue'
import Leave from '@/views/employee/Leave.vue'
import LeaveType from '@/views/admin/LeaveType.vue'
import PositionManagement from '@/views/admin/PositionManagement.vue'
import ApproveLeave from '@/views/employee/ApproveLeave.vue'
import GroupUser from '@/views/admin/GroupUser.vue'
import OverTime from '@/views/employee/OverTime.vue'
import ApproveOvertime from '@/views/employee/ApproveOvertime.vue'
import ShiftHistory from '@/views/admin/ShiftHistory.vue'
import RequestMeal from '@/views/employee/RequestMeal.vue'
import NotifyMeal from '@/views/employee/NotifyMeal.vue'
import DeviceManagement from '@/views/admin/DeviceManagement.vue'
import OvertimeApplication from '@/views/admin/OvertimeApplication.vue'
import FileUpload from '@/views/admin/FileUpload.vue';
import CancelMeal from '@/views/admin/CancelMeal.vue';
import WorkSchedule from '@/views/admin/WorkSchedule.vue';
import AttendanceData from '@/views/admin/AttendanceData.vue';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [

  {
    path: '/login',
    meta: {title: 'login'},
    component: LoginLayout,
  },
  {
    path: '',
    meta: {title: 'homePage'},
    component: HomePage,
  },
  {
    path: '/download-contact-landingpage',
    meta: {title: 'download'},
    component: DownloadContact,
  },
  {
    path: '/welcome',
    meta: {requiresAuth: true,title: 'welcome'},
    component: Welcome,
    beforeEnter (to, from, next) {
      if (!Vue.prototype.$socket) {
        Vue.use(new VueSocketIO({connection: process.env.VUE_APP_API_SERVER}))
      }
      next()
    }
  },
  {
    path: '/welcome2',
    meta: {requiresAuth: true,title: 'welcome'},
    component: Welcome2,
    beforeEnter (to, from, next) {
      if (!Vue.prototype.$socket) {
        Vue.use(new VueSocketIO({connection: process.env.VUE_APP_API_SERVER}))
      }
      next()
    }
  },
  {
    path: '/admin',
    meta: {requiresAuth: true, title: 'admin'},
    component: AdminLayout,
    redirect: '/admin/dashboard',
    children: [
      {path: 'dashboard', component: AdminDashboard,
        beforeEnter (to, from, next) {
          if (!Vue.prototype.$socket) {
            Vue.use(new VueSocketIO({connection: process.env.VUE_APP_API_SERVER}))
          }
          next()
        }
      },
      {path: 'in-out-history', component: InOutHistory, meta: { title:'InOutHistory' }},
      {path: 'employee-manager', component: EmployeeManager, meta: { title:'employeeManager' }},
      {path: 'attendance-management', component: AttendanceManagement, meta: { title:'attendanceManagement' }},
      {path: 'attendance-data', component: AttendanceData, meta: { title:'attendanceData' }},
      {path: 'show-list-shift', component: ShowListShift, meta: { title:'showListShift' }},
      {path: 'arrange-work-shift', component: ArrangeWorkShift, meta: { title:'arrangeWorkShift' }},
      {path: 'staff-in-shift/:id', component: StaffInShifts, meta: {title: 'staffInShift'}},
      {path: 'leave-application', component: LeaveApplication, meta: { title:'leaveApplication' }},
      {path: 'branch-management', component: BranchManagement, meta: { title:'branchManagement' }},
      {path: 'department-manager', component: DepartmentManager, meta: { title:'departmentManager' }},
      {path: 'position-management', component: PositionManagement, meta: { title:'departmentManager' }},
      {path: 'user/:userId', component: EditUser, meta: { title:'user' }},
      {path: 'user', component: AddUser, meta: { title:'user' }},
      {path: 'attendance-report', component: AttendanceReport, meta: { menu:'reportTimeKeep' }},
      {path: 'day-off', component: DayOff, meta: { menu:'dayOff' }},
      {path: 'overtime-application', component: OvertimeApplication, meta: { menu:'overTimeApplication' }},
      {path: 'report-time-keep', component: ReportTimeKeep, meta: { menu:'reportTimeKeep' }},
      {path: 'CheckIn', component: CheckIn, meta: { title:'CheckIn' }},
      {path: 'claim-time-sheet-list', component: ClaimTimeSheetList, meta: { title:'claimTimeSheetList' }},
      {path: 'company-manager', component: CompanyManager, meta: { title:'companyManager' }},
      {path: 'leave-type', component: LeaveType, meta: { title: 'leaveTypeManagement'}},
      {path: 'group-user', component: GroupUser, meta: { title: 'groupUserManagement'}},
      {path: 'shift-history', component: ShiftHistory, meta: {title: 'shiftHistoryManagement' }},
      {path: 'device-management', component: DeviceManagement, meta: {title: "deviceManagement"}},
      {path: 'file-upload', component: FileUpload, meta: {title: "fileUpload"}},
      {path: 'cancel-meal', component: CancelMeal, meta: {title: "cancelMeal"}},
      {path: 'work-schedule', component: WorkSchedule, meta: {title: "workSchedule"}}
	  ]
  },
  {
    path: '/employee',
    meta: {requiresAuth: true, title: 'admin'},
    component: EmployeeLayout,
    redirect: '/employee/dashboard',
    children: [
      {path: 'dashboard', component: EmployeeDashboard},
      {path: 'checkin', component: CheckInEmployee, meta: { title:'CheckIn' }},
      {path: 'in-out-history', component: InOutHistoryEmployee, meta: { title:'InOutHistory' }},
      {path: 'personnel', component: Personnel, meta: { title:'user' }},
      {path: 'leave', component: Leave, meta: {title: 'leaveApplication'}},
      {path: 'approve-leave', component: ApproveLeave, meta: {title: 'leaveApplication'}},
      {path: 'overtime', component: OverTime, meta: {title: 'managementOvertime'}},
      {path: 'approve-overtime', component: ApproveOvertime, meta: {title: 'managementOvertime'}},
      {path: 'request-meal', component: RequestMeal, meta: {title: 'mealRequest'}},
      {path: 'notify-meal', component: NotifyMeal, meta: {title: 'mealNotify'}}
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  const locale = store.getters.locale;
  let t:any;
  if (locale == 'vi') {
    t = msg.messages.vi;
  } else if (locale == 'en') {
    t = msg.messages.en;
  }

  if (to.meta != undefined && to.meta.title != undefined) {
    document.title = t.title[to.meta.title]
  } else {
    let parentRoute = to.matched[0]
    if (parentRoute && parentRoute.meta.title) {
      document.title = t.title[parentRoute.meta.title]
    }
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters.isLogin && !Role.checkRole(to.path, store.getters.role)) {
      next({
        path: '/login',
        query: {redirect: to.fullPath}
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router

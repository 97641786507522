<template>
    <div class="row">
        <div class="card-body m-0 p-0">
            <h2 style="margin-left: 4%; margin-top: 1%;">Công ty cổ phần cao su Đà Nẵng</h2>
            <div>
                <h2 class="text-center text-uppercase">Chi tiết ra vào đầy đủ</h2>
                <span id="date" style="margin-left: 70%; font-size: 16px;">Đà Nẵng, ngày {{ day }} tháng {{ month }} năm
                    {{ year }}</span>
            </div>
            <div class="table-responsive" style="overflow-x: auto;">
                <table id="tbl-preview" class="table b-table table-striped table-hover table-bordered">
                    <tr v-for="(row, i) in previewData" :key="i" :class="getClassForRow(row)">
                        <td v-if="i === 0 || i === 1" :colspan="row.length">{{ row[1] }}</td>
                        <template v-else>
                            <td v-for="(cell, j) in row" v-if="j > 0 && j !== 34" :key="j"
                                :class="{ 'max-width-column': true }"><span
                                    v-if="maNVCells && maNVCells.includes(cell)">Mã NV: <span class="bold-text">{{
                    cell.split(":")[1].trim() }}</span></span>
                                <span v-else-if="hoTenCells && hoTenCells.includes(cell)"> Họ tên: <span
                                        class="bold-text">{{ cell.split(":")[1].trim() }}</span></span>
                                <span v-else>{{ cell }}</span>
                            </td>
                        </template>
                    </tr>
                </table>
            </div>
            <div>
                <span style="margin-left: 80%; font-size: 16px; ">Đà Nẵng, ngày...tháng...năm...</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        previewData: {
            type: [Array, Object]
        },
        departmentName: {
            type: String,
            required: true
        },
        day: {
            type: Number,
            required: true
        },
        month: {
            type: Number,
            required: true
        },
        year: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            maNVCells: [],
            hoTenCells: []
        };
    },
    mounted() {
        this.getMaNVandHoTenCells()
    },
    methods: {
        getClassForRow(row) {
            if (this.hasHoTen(row) || this.hasMaNV(row)) {
                return { 'no-border': true };
            }
            if (this.hasNgayTinhCong(row)) {
                return { 'bold-text': true };
            }
            return {};
        },
        hasMaNV(row) {
            if (!row) return false;
            return row.some(cell => cell && cell.includes && cell.includes('Mã NV'));
        },
        hasHoTen(row) {
            if (!row) return false;
            return row.some(cell => cell && cell.includes && cell.includes('Họ tên'));
        },
        hasNgayTinhCong(row) {
            if (!row) return false;
            return row.some(cell => cell && cell.includes && cell.includes('Ngày tính công'));
        },
        getMaNVandHoTenCells() {
            this.previewData.forEach(row => {
                row.forEach(cell => {
                    if (typeof cell === 'string') {
                        if (cell.includes('Mã NV: ')) {
                            this.maNVCells.push(cell);
                        } else if (cell.includes('Họ tên: ')) {
                            this.hoTenCells.push(cell);
                        }
                    }
                });
            });
        }

    }
}
</script>

<style scoped>
#tbl-preview {
    border: none;
}

#tbl-preview tr:first-child td,
#tbl-preview tr:nth-child(2) td {
    font-weight: bold;
    border: none;
}

#tbl-preview tr:last-child {
    font-weight: bold;
}

.no-border td {
    border: none;
}

.bold-text {
    font-weight: bold;
}
</style>
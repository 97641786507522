<template>
    <div class="row">
        <div class="card-body m-0 p-0">
            <div class="table-responsive" style="overflow-x: auto;">
                <table id="tbl-preview" class="table b-table table-striped table-hover table-bordered">
                    <tr v-for="(row, i) in previewData" :key="i"
                        :class="i >= (previewData.length - 1) ? 'tbl-footer' : ''">
                        <td v-if="i === 0" :colspan="row.length">{{ row[1] }}</td>
                        <template v-else>
                            <td v-for="(cell, j) in row" v-if="j > 0" :key="j" class="max-width-column">{{ cell }}</td>
                        </template>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        previewData: {
            type: [Array, Object]
        }
    }
}
</script>
<style scoped>
#tbl-preview {
    border: none;
}

#tbl-preview tr:first-child td {
    text-align: center;
    font-weight: bold;
    border: none;
}

#tbl-preview tr:nth-child(2) td {
    font-weight: bold;
}

#tbl-preview tr.tbl-footer {
    border: none;
}

#tbl-preview tr.tbl-footer td {
    border: none;
}


</style>
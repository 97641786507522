<template>
  <div id="main-default" class="container-fluid">
    <div class="card col-xl-12">
      <div class="row">
        <div class="col-xl-12">
          <div class="row">
            <div class="col-md-6 col-xs-12">
              <h1 class="page-title mt-2">
                {{ $t("title.managementOvertime") }}
              </h1>
              <p class="text-muted">
                {{ $t("label.totalOvertime") }}: {{ dataTable.length }}
              </p>
            </div>
            <div class="col-md-6 col-xs-12 text-right">
              <div class="mt-4">
                <a
                  href="javascript:void(0)"
                  @click="showModalAddOT()"
                  class="btn btn-danger bg-gradient-danger"
                >
                  <font-awesome-icon icon="plus" />
                  {{ $t("label.addNew") }}</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-12">
          <div class="row">
            <div class="col-xs-12 col-md-4">
              <b-form-group>
                <select v-model="status" class="form-control">
                  <option
                    v-for="status in listStatus"
                    v-bind:value="status.value"
                  >
                    {{ status.text }}
                  </option>
                </select>
              </b-form-group>
            </div>
            <div class="col-md-3 col-xs-12">
              <b-button
                variant="btn btn-info  btn-outline-primary w-100"
                @click="getListOT()"
                >{{ $t("label.search") }}
              </b-button>
            </div>
          </div>
        </div>
        <div class="col-xl-12">
          <div class="row mt-3">
            <div class="col-xl-12">
              <div class="card">
                <div class="card-body m-0 p-0 table-responsive">
                  <b-table
                    bordered="bordered"
                    class="default-table"
                    hover
                    :items="dataTable"
                    :fields="fields"
                    responsive
                    :current-page="currentPage"
                    :per-page="perPage"
                    id="table-overtime"
                    @row-clicked="showInfoDetail"
                    show-empty
                  >
                    <template #cell(approver)="data">
                      <p class="m-0">{{ data.item.approver }}</p>
                    </template>
                    <template #cell(reason)="data">
                      <div class="m-0">
                        {{ data.item.reason | truncate(40, "...") }}
                      </div>
                      <small class="text-danger"
                        >{{ $t("label.timeDay") }}:
                        <template>
                          {{ convertDate(data.item.date_ot) }}
                        </template>
                      </small>
                    </template>
                    <template #cell(approve_status)="data">
                      <div class="m-0 text-white">
                        <template v-if="data.item.approve_status == 3">
                          <span
                            style="
                              background-color: red;
                              border-radius: 10px;
                              line-height: 20px;
                              font-weight: bold;
                              padding: 3px 10px;
                              font-size: 13px;
                            "
                            >{{ $t("label.refuse") }}</span
                          >
                        </template>
                        <template v-else-if="data.item.approve_status == 1">
                          <span
                            style="
                              background-color: rgb(45 189 76);
                              border-radius: 10px;
                              line-height: 20px;
                              font-weight: bold;
                              padding: 3px 10px;
                              font-size: 13px;
                            "
                            >{{ $t("label.agree") }}</span
                          >
                        </template>
                        <template v-else-if="data.item.approve_status == 2">
                          <span
                            style="
                              background-color: lightgreen;
                              border-radius: 10px;
                              line-height: 20px;
                              font-weight: bold;
                              padding: 3px 10px;
                              font-size: 13px;
                            "
                            >{{ $t("label.pending") }}</span
                          >
                        </template>
                        <template v-else>
                          <span
                            style="
                              background-color: rgb(251 99 64);
                              border-radius: 10px;
                              line-height: 20px;
                              font-weight: bold;
                              padding: 3px 10px;
                              font-size: 13px;
                            "
                            >{{ $t("label.waitingForApprove") }}</span
                          >
                        </template>
                      </div>
                    </template>
                    <template #cell(function)="data">
                      <button
                        class="btn btn-danger btn-sm"
                        @click="showDelete(data.item.id)"
                        v-b-tooltip.hover
                        :title="$t('label.delete')"
                        :disabled="editAble(data.item.approve_status)"
                      >
                        <font-awesome-icon icon="trash" />
                      </button>
                    </template>
                    <template #empty="scope">
                      {{ $t("notify.message.nodata") }}
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="dataTable.length"
                    :per-page="perPage"
                    align="right"
                    size="sm"
                    class="m-4"
                    aria-controls="table-overtime"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- modal delete  -->
    <b-modal
      v-model="modalDelete"
      ref="modal-delete"
      hide-header
      hide-footer
      header-bg-variant="secondary"
      modal-class="modal-delete text-center"
      centered
      size="md"
    >
      <div>
        <div class="modal-delete--warning">
          <div class="modal-delete--warning__content">!</div>
        </div>
        <div>
          <h2 class="text-warning">{{ $t("label.areYouSure") }}?</h2>
        </div>
        <div>{{ $t("label.sureOvertime") }}</div>
        <div class="mt-3">
          <b-button variant="primary" @click="deleteOT(idDelete)"
            >{{ $t("label.iAgree") }}
          </b-button>
          <b-button variant="secondary" @click="hideModalDelete"
            >{{ $t("label.cancel") }}
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-modal
      v-model="modalCheckApprover"
      ref="modal-delete"
      hide-header
      hide-footer
      header-bg-variant="secondary"
      modal-class="modal-delete text-center"
      centered
      size="md"
    >
      <div>
        <div class="modal-delete--warning">
          <div class="modal-delete--warning__content">!</div>
        </div>
        <div>
          <h2 class="text-warning">{{ $t("label.areYouSure") }}?</h2>
        </div>
        <div>{{ $t("label.sureOvertime") }}</div>
        <div class="mt-3">
          <b-button variant="secondary" @click="hideModalDelete"
            >{{ $t("label.cancel") }}
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-modal
      v-model="modalAddOT"
      ref="modal-add-ot"
      :title="$t('label.addOverTime')"
      hide-footer
      header-bg-variant="secondary"
      centered
      size="xl"
    >
      <b-form ref="form" class="row">
        <div class="col-lg-6 col-md-6 col-xs-12">
          <b-form-group class="required-mess" :label="$t('label.chooseDate')">
            <flat-pickr
              v-model="dateSelect"
              :config="configDateTimePicker"
              :placeholder="$t('label.chooseDate')"
              name="reportDate"
              class="form-control"
            ></flat-pickr>
            <div class="error-message w-100 clear text-left">
              {{ msg["dateOvertime"] ? $t(msg["dateOvertime"]) : "" }}
            </div>
          </b-form-group>
        </div>
        <div class="col-lg-6 col-md-6 col-xs-12">
          <b-form-group
            class="required-mess"
            :label="$t('label.minuteOvertime')"
          >
            <b-form-input
              type="number"
              v-model="minuteOT"
              min="0"
              max="200"
            ></b-form-input>
            <div class="error-message w-100 clear text-left">
              {{ msg["minuteOvertime"] ? $t(msg["minuteOvertime"]) : "" }}
            </div>
          </b-form-group>
        </div>
        <div class="col-lg-6 col-md-6 col-xs-12">
          <b-form-group
            id="input-group-4"
            :label="$t('label.shift')"
            class="required-mess"
          >
            <select v-model="shiftId" class="form-control">
              <option v-for="shift in shifts" v-bind:value="shift.id">
                {{ shift.shifts_name }}
              </option>
            </select>
            <div class="error-message w-100 clear text-left">
              {{ msg["shiftId"] ? $t(msg["shiftId"]) : "" }}
            </div>
          </b-form-group>
        </div>
        <div class="col-lg-6 col-md-6 col-xs-12">
          <b-form-group
            class="required-mess"
            id="input-group-2"
            :label="$t('label.reasonForOT')"
          >
            <textarea
              class="form-control form-control-sm"
              id="tar-2"
              :placeholder="$t('label.reasonForOT')"
              required
              v-model="reasonForOT"
              rows="3"
            ></textarea>
            <div class="error-message w-100 clear text-left">
              {{ msg["reason"] ? $t(msg["reason"]) : "" }}
            </div>
          </b-form-group>
        </div>
        <!-- <div class="row col-12">
          <div class="col-12">
            <h3>{{ $t('label.approveStep') }} 1</h3>
          </div>
          <div class="col-lg-6 col-md-6 col-xs-12">
            <b-form-group id="input-group-4" :label="$t('label.jobPosition')" label-for="tar-2">
              <select v-model="positionId" class="form-control">
                <option v-for="position in listPosition" v-bind:value="position.id">
                  {{ position.name }}
                </option>
              </select>
              <div class="error-message w-100 clear text-left">
                {{ msg['shiftId'] ? $t(msg['shiftId']) : '' }}
              </div>
            </b-form-group>
          </div>
          <div class="col-lg-6 col-md-6 col-xs-12">
            <b-form-group id="input-group-4" :label="$t('label.approver')" label-for="tar-2">
              <select v-model="shiftId" class="form-control">
                <option v-for="shift in shifts" v-bind:value="shift.id">
                  {{ shift.shifts_name }}
                </option>
              </select>
              <div class="error-message w-100 clear text-left">
                {{ msg['shiftId'] ? $t(msg['shiftId']) : '' }}
              </div>
            </b-form-group>
          </div>
        </div> -->
        <div class="row col-12" v-for="(approval, i) in approveList" :key="i">
          <div class="col-12">
            <h3>{{ $t("label.approveStep") }} {{ i + 1 }}</h3>
          </div>
          <div class="col-6">
            <b-form-group :label="$t('label.jobPosition')">
              <treeselect
                v-model="approval.position"
                :multiple="false"
                :options="approval.positionOption"
                :default-expand-level="Infinity"
                @select="changePosition(i)"
                :noChildrenText="$t('label.noNodeChildren')"
                :placeholder="$t('label.choosePosition')"
              />
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group :label="$t('label.approver')">
              <select class="form-control" v-model="approval.user">
                <option
                  v-for="user in approval.listUser"
                  v-bind:value="user.id"
                >
                  {{ user.fullName }}
                </option>
              </select>
              <div class="error-message w-100 clear text-left">
                {{
                  msg["approveBy[" + i + "]"]
                    ? $t(msg["approveBy[" + i + "]"])
                    : ""
                }}
              </div>
            </b-form-group>
          </div>
        </div>
        <div class="col-12">
          <b-button @click="addMoreApprove">{{
            $t("label.addApproveStep")
          }}</b-button>
        </div>
      </b-form>
      <div class="d-block text-center">
        <div class="text-center">
          <b-button class="btn btn-danger" @click="addOvertime"
            >{{ $t("label.save") }}
          </b-button>
          <b-button class="btn btn-default" @click="hidemodalAddLeave"
            >{{ $t("label.cancel") }}
          </b-button>
        </div>
      </div>
    </b-modal>
    <!-- modal addLeave  -->
    <b-modal
      v-model="modalInfoDetail"
      ref="modal-info-detail"
      hide-header
      hide-footer
      header-bg-variant="secondary"
      modal-class="modal-info-detail text-center"
      centered
      size="lg"
    >
      <div>
        <div>
          <table class="table table-bordered">
            <thead class="thead-light">
              <tr>
                <th style="text-transform: none; font-size: 18px">
                  {{ $t("label.sender") }}
                </th>
                <th style="text-transform: none; font-size: 18px">
                  {{ $t("label.browser") }}
                </th>
              </tr>
            </thead>
            <tr class="text-left">
              <td>
                <p>{{ infoSenderDetail.fullName }}</p>
                <p>{{ infoSenderDetail.email }}</p>
              </td>
              <td>
                <table>
                  <tr>
                    <th>{{ $t("label.browser") }}</th>
                    <th>{{ $t("label.status") }}</th>
                    <th>{{ $t("label.approveStep") }}</th>
                    <th>{{ $t("label.content") }}</th>
                  </tr>
                  <tr v-for="(item, index) in infoSenderDetail.listApprover">
                    <td>{{ item.fullName }}</td>
                    <td>{{ getStatus(item.approve_status) }}</td>
                    <td>{{ item.approve_step }}</td>
                    <td>{{ item.approve_content }}</td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr class="text-left">
              <td colspan="2">
                <b>{{ $t("label.status") }}: </b>
                <template v-if="infoSenderDetail.approve_status == 3">
                  <span
                    style="
                      background-color: red;
                      border-radius: 10px;
                      line-height: 20px;
                      font-weight: bold;
                      padding: 3px 10px;
                      font-size: 13px;
                      color: #fff;
                    "
                    >{{ $t("label.refuse") }}</span
                  >
                </template>
                <template v-else-if="infoSenderDetail.approve_status == 1">
                  <span
                    style="
                      background-color: rgb(45 189 76);
                      border-radius: 10px;
                      line-height: 20px;
                      font-weight: bold;
                      padding: 3px 10px;
                      font-size: 13px;
                      color: #fff;
                    "
                    >{{ $t("label.agree") }}</span
                  >
                </template>
                <template v-else-if="infoSenderDetail.approve_status == 2">
                  <span
                    style="
                      background-color: lightgreen;
                      border-radius: 10px;
                      line-height: 20px;
                      font-weight: bold;
                      padding: 3px 10px;
                      font-size: 13px;
                      color: #fff;
                    "
                    >{{ $t("label.pending") }}</span
                  >
                </template>
                <template v-else>
                  <span
                    style="
                      background-color: rgb(251 99 64);
                      border-radius: 10px;
                      line-height: 20px;
                      font-weight: bold;
                      padding: 3px 10px;
                      font-size: 13px;
                      color: #fff;
                    "
                    >{{ $t("label.waitingForApprove") }}</span
                  >
                </template>
              </td>
            </tr>
            <thead class="thead-light">
              <tr>
                <th colspan="2" style="text-transform: none; font-size: 18px">
                  {{ $t("label.reasonForOvertime") }}
                </th>
              </tr>
            </thead>
            <tr class="text-left">
              <td colspan="2">
                <p>
                  <strong>{{ $t("label.timeDay") }}:</strong>
                  {{ convertDate(infoSenderDetail.date_ot, "dd/MM/yyyy") }}
                </p>
                <p>
                  <strong>{{ $t("label.minuteOvertime") }}:</strong>
                  {{ infoSenderDetail.minute_ot }}
                </p>
                <p>
                  <strong>{{ $t("label.reason") }}:</strong>
                  {{ infoSenderDetail.reason }}
                </p>
              </td>
            </tr>
            <thead
              class="thead-light"
              v-if="infoSenderDetail.approve_status == 3"
            >
              <tr>
                <th colspan="2" style="text-transform: none; font-size: 18px">
                  {{ $t("label.reasonDenied") }}
                </th>
              </tr>
            </thead>
            <tr class="text-left" v-if="infoSenderDetail.approve_status == 3">
              <td colspan="2">
                <p>
                  <strong>{{ $t("label.reason") }}:</strong>
                  {{ infoSenderDetail.reason_denied }}
                </p>
              </td>
            </tr>
          </table>
        </div>

        <div class="mt-3">
          <b-button variant="secondary" @click="hideModalInfoDetail"
            >{{ $t("label.close") }}
          </b-button>
        </div>
      </div> </b-modal
    ><!-- modal show Info Detail  -->
  </div>
</template>

<script>
import apiClient from "@/axios";
import apiServer from "@/axiosServer";
import flatPickr from "vue-flatpickr-component";
import FlatpickrLanguages from "flatpickr/dist/l10n";
import moment from "moment";
import Swal from "sweetalert2";
import Treeselect from "@riophae/vue-treeselect";
export default {
  components: {
    flatPickr,
    Treeselect,
  },
  data() {
    return {
      modalDelete: false,
      modalInfoDetail: false,
      dataTable: [],
      showDeleteSuccess: false,
      currentPage: 1,
      perPage: 25,
      totalTakeLeave: 5,
      modalAddOT: false,
      modalCheckApprover: false,
      fields: [
        {
          label: this.$t("label.browser"),
          key: "browser",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t("label.minuteOvertime"),
          key: "minute_ot",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t("label.reason"),
          key: "reason",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t("label.status"),
          key: "approve_status",
          class: "align-middle",
          sortable: false,
        },
        {
          label: "",
          key: "function",
          class: "align-middle text-center",
          sortable: true,
        },
      ],
      infoSenderDetail: [],
      id: null,
      addNewsuccess: false,
      addNewError: false,
      reasonForOT: "",
      minuteOT: null,
      dateSelect: "",
      configDateTimePicker: {
        dateFormat: "d/m/Y",
        mode: "single",
        maxDate: "today",
        locale: FlatpickrLanguages["en"],
        shorthandCurrentMonth: true,
      },
      options: [],
      msg: [],
      status: null,
      listStatus: [
        { text: this.$t("label.status"), value: null },
        { text: this.$t("label.waitingForApprove"), value: 0 },
        { text: this.$t("label.approved"), value: 1 },
        { text: this.$t("label.pending"), value: 2 },
        { text: this.$t("label.refuse"), value: 3 },
      ],
      shiftId: null,
      shifts: [],
      positionId: null,
      approveBy: null,
      leaveTypes: [],
      leaveType: null,
      listPosition: [],
      approveList: [],
    };
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        text = text.substring(0, length);
        text = text.substring(0, text.lastIndexOf(' '));
        return text + suffix;
      } else {
        return text;
      }
    }
  },
  created() {
    this.getListOT();
    this.getPosition();
    this.getShift();
    if (this.$store.getters.locale == "vi") {
      this.configDateTimePicker.locale = FlatpickrLanguages["vn"];
    }
  },
  methods: {
    async getListOT() {
      let res;
      try {
        this.dataTable = [];
        res = await apiServer.post("/api/v1/user/overtime/list", {
          status: this.status,
        });
        if (res && res.data) {
          this.dataTable = res.data;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getPosition() {
      let res;
      try {
        res = await apiServer.post("api/v1/position/list", {
          placeId: this.branchid,
          positionId: this.positionId,
        });
      } catch (error) {}
      if (res && res.data) {
        this.listPosition = [];
        if (res.data.length > 0) {
          this.listPosition = res.data;
          for (var i = 0; i < this.approveList.length; i++) {
            this.approveList.positionOption = this.listPosition;
          }
        }
      }
    },
    async getApproverByPosition(index) {
      let res;
      try {
        res = await apiServer.post("api/v1/user/search-list-approver", {
          position: this.approveList[index].position,
        });
      } catch (error) {}
      if (res && res.data) {
        this.approveList[index].listUser = [];
        if (res.data.length > 0) {
          this.approveList[index].listUser = res.data;
        }
      }
    },
    async getShift() {
      let res;
      try {
        res = await apiServer.get("api/v1/user/get-shift", {});
      } catch (error) {}
      if (res && res.data) {
        this.shifts = res.data;
      }
    },
    changePosition(index) {
      setTimeout(() => {
        this.getApproverByPosition(index);
      }, 500);
    },
    addMoreApprove() {
      this.approveList.push({
        positionOption: this.listPosition,
        position: null,
        listUser: [],
        user: null,
      });
    },
    hideModalDelete() {
      this.$refs["modal-delete"].hide();
    },

    hideModalInfoDetail() {
      this.$refs["modal-info-detail"].hide();
    },

    hidemodalAddLeave() {
      this.$refs["modal-add-ot"].hide();
    },
    async showInfoDetail(item) {
      let res;
      try {
        res = await apiServer.post("/api/v1/overtime/get-by-id", {
          overtime_id: item.id,
        });
        if (res && res.data) {
          this.infoSenderDetail = res.data;
        }
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
      this.modalInfoDetail = true;
    },
    showDelete(idDelete) {
      this.idDelete = idDelete;
      this.showDeleteSuccess = false;
      this.modalDelete = true;
    },
    showEdit(id) {
      this.id = id;
      let editItem = this.dataTable.find((x) => x.id == id);
      this.reasonForOT = editItem.reason;
      this.approveBy = editItem.browser;
      this.dateSelect = moment(editItem.date_ot).format("DD/MM/yyyy");
      this.minuteOT = editItem.minute_ot;
      this.modalAddOT = true;
    },
    showModalCheckApprover() {},

    showModalAddOT() {
      this.dateSelect = null;
      this.approveBy = null;
      this.reasonForOT = "";
      this.minuteOT = null;
      this.modalAddOT = true;
      this.managementOvertime = [];
      this.msg = [];
    },
    async deleteOT(id) {
      let res;
      try {
        res = await apiServer.delete("/api/v1/user/overtime/" + id, {});
        if (res && res.data) {
          this.modalDelete = false;
          this.$notify({
            group: "bottomnotification",
            title: this.$t("label.notification"),
            text: this.$t(res.data.message),
            position: "bottom-center",
          });
          this.getListOT();
        }
      } catch (e) {
        console.log(e);
      }
    },
    async addOvertime() {
      let res;
      var approveUser = [];
      for (var i = 0; i < this.approveList.length; i++) {
        approveUser.push(this.approveList[i].user);
      }
      if (approveUser.length === 0) {
        this.$notify({
          group: "bottomnotification",
          title: this.$t("label.notificationError"),
          text: this.$t("error.message.userApproveEmpty"),
          type: "error",
        });
        return false;
      }
      if (this.hasDuplicates(approveUser)) {
        // alert(this.$t('error.message.userApproveDuplicate'));
        this.$notify({
          group: "bottomnotification",
          title: this.$t("label.notificationError"),
          text: this.$t("error.message.userApproveDuplicate"),
          type: "error",
        });
        return false;
      }
      try {
        res = await apiServer.post("/api/v1/user/overtime", {
          minuteOvertime: this.minuteOT,
          shiftId: this.shiftId,
          dateOvertime: this.convertTimestampSendDate(this.dateSelect),
          reason: this.reasonForOT.trim(),
          approveBy: approveUser,
        });
        if (res && res.data) {
          if (res.status === 200) {
            this.modalAddOT = false;
            this.$notify({
              group: "bottomnotification",
              title: this.$t("label.notification"),
              text: this.$t(res.data.message),
              position: "bottom-center",
            });
            this.getListOT();
          }
        }
      } catch (error) {
        if (error.response.status === 400) {
          this.msg = error.response.data;
        }
        if (error.response.data["message"]) {
          this.$notify({
            group: "bottomnotification",
            title: this.$t("label.notificationError"),
            text: this.$t(this.msg["message"]),
            type: "error",
          });
        } else {
          console.log(error.response.data);
          // alert(this.$t(error.response.data));
        }
      }
    },
    deleteAble(status, dateId) {
      if (status != 0) {
        return true;
      } else {
        let today = new Date();
        let dt = new Date(dateId);
        let date2 = today.getTime() - dt.getTime();
        let date3 = Math.ceil(date2 / (1000 * 60 * 60 * 24));
        return date3 > 1;
      }
    },
    editAble(status) {
      return status != 0;
    },
    getStatus(status) {
      if (status == 1) {
        return this.$t("label.agree");
      } else if (status == 2) {
        return this.$t("label.pending");
      } else if (status == 3) {
        return this.$t("label.refuse");
      } else {
        return this.$t("label.waitingForApprove");
      }
    },
    convertDate(inputDate) {
      if (inputDate == undefined || inputDate == null) return "";
      return moment(inputDate).format("DD/MM/yyyy");
    },
    convertTimestampSendDate(date) {
      if (date == null) return null;
      const dateString = date;
      const changedDate = dateString.replace(/(..)\/(..)\/(....)/, "$3-$2-$1");
      var date = new Date(changedDate);
      return moment(date).format("yyyy-MM-DD");
    },
    hasDuplicates(arr) {
      return arr.some(
        (x) => x !== null && arr.indexOf(x) !== arr.lastIndexOf(x)
      );
    },
  },
};
</script>

<style scoped></style>

import axios from 'axios';
import store from '@/store';
import router from '@/router'

const URL = process.env.VUE_APP_API_SERVER
const apiServer = axios.create({
	baseURL: URL,
  timeout: 300000,
  headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
  }
})

apiServer.interceptors.request.use(function (config) {
  // Do something before request is sent
  let authKey = store.getters.jwt;
  let locale = store.getters.locale;
  config.headers["Authorization"] = "" + authKey;
  config.headers["Accept-Language"] = locale;
  return config;
})

apiServer.interceptors.response.use((response) => response, (error) => {
  // whatever you want to do with the error
  if (error.response) {
	let status = error.response.status;
    if (status == 403) {
	  router.push({ path: '/login'});
	  return;
    }
  } else {
	  router.push({ path: '/login'});
	  return;
  }

  throw error;
});

export default apiServer;

<template>
  <!-- Page content -->
  <div id="main-default" class="container-fluid mt-3">
    <div class="card col-12">
      <div class="row mt-3">
        <div class="col-xs-6 col-md-3 col-lg-2">
          <b-form-group :label="$t('label.reportType')">
            <select v-model="reportType" class="form-control" @change="reportTypeChange">
              <option v-for="listReportType in listReportTypes" v-bind:value="listReportType.value">
                {{ listReportType.text }}
              </option>
            </select>
          </b-form-group>
        </div>
        <div class="col-xs-6 col-md-3 col-lg-2" v-if="isSupperAdmin || isComAdmin">
          <b-form-group :label="$t('label.branch')">
            <select v-model="placeId" class="form-control" @change="changeBranch">
              <option v-for="branch in branchs" v-bind:value="branch.value">
                {{ branch.text }}
              </option>
            </select>
          </b-form-group>
        </div>

        <div class="col-xs-6 col-md-3 col-lg-2" v-if="reportByDepart">
          <b-form-group class="required-mess" :label="$t('label.department')">
            <treeselect v-model="departmentId" :multiple="false" :options="departmentOption"
              :default-expand-level="Infinity" :noChildrenText="$t('label.noNodeChildren')"
              :placeholder="$t('label.chooseDepartment')" />
          </b-form-group>
        </div>



        <div class="col-xs-6 col-lg-2 col-md-3" v-if="reportByPos">
          <b-form-group :label="$t('label.jobPosition')">
            <treeselect v-model="positionId" :multiple="false" :options="positionOption"
              :default-expand-level="Infinity" :noChildrenText="$t('label.noNodeChildren')"
              :placeholder="$t('label.choosePosition')" />
          </b-form-group>
        </div>
        <div class="col-xs-6 col-lg-2 col-md-3" v-if="reportByDate">
          <b-form-group :label="$t('label.selectDateRange')">
            <flat-pickr v-model="dateSelect" :config="configDateTimePicker" :placeholder='$t("label.selectDateRange")'
              name="reportDate" class="form-control"></flat-pickr>
          </b-form-group>
        </div>
        <div v-if="reportByMonth" class="col-xs-6 col-lg-2 col-md-3">
          <b-form-group :label="$t('label.chooseMonth')">
            <select v-model="month" class="form-control">
              <option v-for="m in monthOptions" v-bind:value="m.value">
                {{ m.text }}
              </option>
            </select>
          </b-form-group>
        </div>
        <div v-if="reportByMonth" class="col-xs-6 col-lg-2 col-md-3">
          <b-form-group :label="$t('label.chooseYear')">
            <select v-model="year" class="form-control">
              <option v-for="y in yearOptions" v-bind:value="y.value">
                {{ y.text }}
              </option>
            </select>
          </b-form-group>
        </div>
        <div class="col-xs-6 col-lg-2 col-md-3" v-if="reportByUser">
          <b-form-group :label="$t('label.employeeCode')">
            <div class="autocomplete" v-click-outside="onClickOutside">
              <b-form-input v-model="aliasId" @input="onChangeCode" type="text">
              </b-form-input>
              <ul v-show="isOpenCodeRes" class="autocomplete-results">
                <li v-for="(result, i) in codeResults" :key="i" @click="setCodeResult(result)"
                  class="autocomplete-result">
                  {{ result.value }} - {{ result.text }}
                </li>
              </ul>
            </div>
          </b-form-group>
        </div>
        <div class="col-xs-6 col-lg-2 col-md-3" v-if="reportByUser">
          <b-form-group :label="$t('label.fullName')">
            <div class="autocomplete" v-click-outside="handleClickOutside">
              <b-form-input v-model="fullName" @input="onChangeName" type="text">
              </b-form-input>
              <ul v-show="isOpenNameRes" class="autocomplete-results">
                <li v-for="(result, i) in nameResults" :key="i" @click="setNameResult(result)"
                  class="autocomplete-result">
                  {{ result.value }} - {{ result.text }}
                </li>
              </ul>
            </div>
          </b-form-group>
        </div>
        <div class="col-xs-6 col-lg-2 col-md-3" v-if="reportByLateEarly">
          <b-form-group :label="$t('label.late') + '/' + $t('label.comeBackBefore')">
            <b-form-checkbox v-model="lateEarly"></b-form-checkbox>
          </b-form-group>
        </div>
        <div class="col-12 text-right mb-2">
          <a href="javascript:void(0)" class="btn btn-danger bg-gradient-danger" @click="previewreport()">{{
            $t("label.preview") }}
          </a>
          <a href="javascript:void(0)" class="btn btn-danger bg-gradient-danger" @click="exportReport()">{{
            $t("label.exportExcel") }}
          </a>
          <a href="javascript:void(0)" class="btn btn-danger bg-gradient-danger" @click="exportPdf()">{{
            $t("label.exportPdf") }}
          </a>
        </div>
      </div>

    </div>
    <div class="card col-12 pt-4">
      <div class="row">
        <div class="col-12 col-md-6">
          <div>
            <div class="card bg-gradient-warning border-success" style="min-height: 400px">
              <div class="text-center p-3">
                <h5 class="h3 mb-0 text-white">
                  {{ $t("label.statisticsToday") }}
                </h5>
              </div>
              <div class="card-body" style="">
                <div class="row">
                  <div class="col-md-4">
                    <div class="card text-center">
                      <div class="card-body p-2">
                        <p class="text-danger font-weight-bold p-0 m-0">
                          {{ infoCheckin.checkedin }}/{{ infoCheckin.accountNumber }}</p>
                        <span>{{ $t("label.working") }}</span>
                      </div>
                    </div>
                    <div class="card border text-center">
                      <div class="card-body p-2">
                        <p class="text-danger font-weight-bold p-0 m-0">{{ infoCheckin.lateNumber }}</p>
                        <span>{{ $t("label.late") }}</span>
                      </div>
                    </div>
                    <div class="card text-center">
                      <div class="card-body p-2">
                        <p class="text-danger font-weight-bold p-0 m-0">{{ infoCheckin.notCheckin }}</p>
                        <span>{{ $t("label.absent") }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-8 list-person-in-out">
                    <div class="list-person-in-out--item" v-for="(item, index) in countInoutIINnfos">
                      <div class="list-person-in-out--item__img">
                        <img :src="item.avatar" alt="">
                      </div>
                      <div class="list-person-in-out--item__content">
                        <h3>{{ item.fullName }}</h3>
                        <p>{{ $t('label.turnInOut') }}: {{ item.countinout }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="card border-0">
            <div class="container">
              <apexchart height="389" :options="options" :series="series"></apexchart>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <b-modal v-model="modalPreview" id="modal-preview" modal-class="modal-fullscreen" title="Xem trước báo cáo"
        header-bg-variant="gradient-primary" header-text-variant="light" body-bg-variant="secondary" centered
        modal-content-class="table-modal-content">
        <div id="print" class="table-modal-content">
          <div class="contain" style="margin-left: 3%; margin-right: 3%; border-color: black;">
            <preview-report1 v-if="reportType == 6" id="preview-type1" :preview-data="previewData" :day="day"
              :month="month" :year="year" :departmentName="departmentName" :departmentUnit="departmentUnit"
              style="font-size: 12pt;">
            </preview-report1>
            <preview-report2 v-if="reportType == 7" id="preview-type2" :preview-data="previewData" :day="day"
              :month="currentMonth" :year="year" :departmentName="departmentName" style="font-size: 12pt;">
            </preview-report2>
            <preview-report v-if="reportType == 8" id="preview-type3" :preview-data="previewData" :day="day"
              :month="month" :year="year" :departmentName="departmentName" :departmentUnit="departmentUnit"
              style="font-size: 12pt;">
            </preview-report>
            <preview-report4 v-if="reportType == 9" id="preview-type4" :preview-data="previewData" :day="day"
              :month="month" :year="year" :departmentName="departmentName" style="font-size: 12pt;">
            </preview-report4>
            <preview-report5 v-if="reportType == 10" id="preview-type5" :preview-data="previewData" :day="day"
              :month="month" :year="year" :departmentName="departmentName" style="font-size: 12pt;">
            </preview-report5>
            <preview-report6 v-if="reportType == 11" id="preview-type7" :preview-data="previewData" :day="day"
              :month="month" :year="year" :departmentName="departmentName" style="font-size: 12pt;">
            </preview-report6>
            <preview-report7 v-if="reportType == 12" id="preview-type8" :preview-data="previewData" :day="day"
              :month="month" :year="year" :departmentName="departmentName" style="font-size: 12pt;">
            </preview-report7>
          </div>
        </div>
        <template v-slot:modal-footer="{ cancel }">
          <b-button @click="generatePDF()">{{ $t('label.exportPdf') }}</b-button>
          <b-button @click="exportReport()">{{ $t('label.exportExcel') }}</b-button>
          <b-button @click="print()">{{ $t('label.print') }}</b-button>
          <b-button @click="cancel()">{{ $t('label.cancel') }}</b-button>
        </template>
      </b-modal>
    </div>
    <div class="col-12">
      <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="true" :preview-modal="false"
        :paginate-elements-by-height="1000" :filename="fileName" :pdf-quality="1" :manual-pagination="false"
        pdf-format="a1" :pdf-margin="10" pdf-orientation="landscape" pdf-content-width="97%"
        @progress="onPdfProgress($event)" ref="html2Pdf" style="font-size: 12pt;">
        <section slot="pdf-content" class="pdf-content">
          <div class="contain" style="margin-left: 3%; margin-right: 3%; border-color: black;">
            <preview-report1 v-if="reportType == 6" id="preview-type1" :preview-data="previewData" :day="day"
              :month="month" :year="year" :departmentName="departmentName" :departmentUnit="departmentUnit"
              style="font-size: 12pt;">
            </preview-report1>
            <preview-report2 v-if="reportType == 7" id="preview-type2" :preview-data="previewData" :day="day"
              :month="currentMonth" :year="year" :departmentName="departmentName" style="font-size: 12pt;">
            </preview-report2>
            <preview-report v-if="reportType == 8" id="preview-type3" :preview-data="previewData" :day="day"
              :month="month" :year="year" :departmentName="departmentName" :departmentUnit="departmentUnit"
              style="font-size: 12pt;">
            </preview-report>
            <preview-report4 v-if="reportType == 9" id="preview-type4" :preview-data="previewData" :day="day"
              :month="month" :year="year" :departmentName="departmentName" style="font-size: 12pt;">
            </preview-report4>
            <preview-report5 v-if="reportType == 10" id="preview-type5" :preview-data="previewData" :day="day"
              :month="month" :year="year" :departmentName="departmentName" style="font-size: 12pt;">
            </preview-report5>
            <preview-report6 v-if="reportType == 11" id="preview-type5" :preview-data="previewData" :day="day"
              :month="month" :year="year" :departmentName="departmentName" style="font-size: 12pt;">
            </preview-report6>
            <preview-report7 v-if="reportType == 12" id="preview-type5" :preview-data="previewData" :day="day"
              :month="month" :year="year" :departmentName="departmentName" style="font-size: 12pt;">
            </preview-report7>
          </div>
        </section>
      </vue-html2pdf>
    </div>
    <pulse-loader :loading="loading"></pulse-loader>
  </div>
</template>

<script>
import apiClient from '@/axios'
import apiServer from '@/axiosServer'
import flatPickr from 'vue-flatpickr-component'
import VueApexCharts from "vue-apexcharts"
import FlatpickrLanguages from "flatpickr/dist/l10n"
import Treeselect from '@riophae/vue-treeselect'
import VueHtml2pdf from "vue-html2pdf"
import PreviewReport from '@/components/PreviewReport.vue'
import PreviewReport1 from '@/components/PreviewReport1.vue'
import PreviewReport2 from '@/components/PreviewReport2.vue'
import PreviewReport4 from '@/components/PreviewReport4.vue'
import PreviewReport5 from '@/components/PreviewReport5.vue'
import PreviewReport6 from '@/components/PreviewReport6.vue'
import PreviewReport7 from '@/components/PreviewReport7.vue'
import PulseLoader from '@/components/PulseLoader.vue'
import moment from 'moment'
import Vue from "vue"
import { Printd } from 'printd'
import ExcelJS from 'exceljs';
import jsPDF from 'jspdf';
import * as XLSX from 'xlsx';
import 'jspdf-autotable';

Vue.directive("click-outside", {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

export default {
  components: {
    flatPickr,
    Treeselect,
    VueHtml2pdf,
    PreviewReport,
    PreviewReport1,
    PreviewReport2,
    PreviewReport4,
    PreviewReport5,
    PreviewReport6,
    PreviewReport7,
    PulseLoader
  },
  data() {
    return {
      isSupperAdmin: false,
      isComAdmin: false,
      loading: false,
      accountNumber: '',
      lateNumber: '',
      branchNumber: '',
      workingNumber: '',
      absentNumber: '',
      reportType: 1,
      dateSelect: '',
      placeId: null,
      branchs: [],
      listReportTypes: [
        /*{ text: this.$t('label.workDetail'), value: 1 },
        { text: this.$t('label.checkinDetail'), value: 2 },
        { text: this.$t('label.checkinByDay'), value: 3 },
        { text: this.$t('label.checkinMoreDay'), value: 4 },
        { text: this.$t('label.workDayDetail'), value: 5 },*/
        { text: this.$t('label.mealsInMonth'), value: 6 },
        { text: this.$t('label.detailCheckin'), value: 7 },
        { text: this.$t('label.generalReport'), value: 8 },
        { text: this.$t('label.lateReport'), value: 11 },
        { text: this.$t('label.earlyReport'), value: 12 },
        { text: this.$t('label.shiftReport'), value: 9 },
        { text: this.$t('label.leaveReport'), value: 10 },
      ],
      configDateTimePicker: {
        dateFormat: 'd/m/Y',
        mode: "range",
        maxDate: "today",
        locale: FlatpickrLanguages["en"],
        shorthandCurrentMonth: true
      },
      options: {
        chart: {
          id: "vuechart-example",
          type: 'bar',

          height: 300,
          width: '100%'
        },
        xaxis: {
          categories: [],
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }]
      },
      series: [],
      infoCheckin: {},
      countInoutIINnfos: [],
      departmentId: null,
      positionId: null,
      aliasId: null,
      fullName: null,
      reportByMonth: false,
      reportByDate: false,
      reportByDepart: false,
      reportByPos: false,
      reportByUser: false,
      reportByLateEarly: false,
      month: 0,
      year: 0,
      day: 0,
      monthOptions: [],
      yearOptions: [],
      departmentOption: [],
      departments: [],
      positionOption: null,
      lateEarly: null,
      isOpenCodeRes: false,
      isOpenNameRes: false,
      modalPreview: false,
      nameResults: [],
      codeResults: [],
      previewData: [],
      fileName: '',
      departmentName: '',
      isMerged: false,
      isMerge2: false,
      departmentUnit: '',
      currentDay: 0,
      currentMonth: 0,
      currentYear: 0,
    };
  },
  created() {
    let today = new Date();
    this.day = today.getDate()
    this.currentMonth = today.getMonth() + 1;
    this.month = (today.getMonth() + 1).toString().padStart(2, '0');
    this.year = today.getFullYear();
    for (let i = 1; i <= 12; i++) {
      if (i < 10) {
        this.monthOptions.push({ text: '0' + i, value: '0' + i });
      } else {
        this.monthOptions.push({ text: i, value: i });
      }
    }
    for (let j = this.year; j >= this.year - 10; j--) {
      this.yearOptions.push({ text: j, value: j });
    }
    if (this.$store.getters.superAdmin == true) {
      this.isSupperAdmin = true;
    }
    if (this.$store.getters.comAdmin == true) {
      this.isComAdmin = true;
    }
  },
  mounted() {
    if (this.isSupperAdmin || this.isComAdmin) {
      this.getListBranch();
    } else {
      this.getDepartment();
      this.getPosition();
    }
    this.getSummary();
    this.getStatistic();
    if (this.$store.getters.locale == 'vi') {
      this.configDateTimePicker.locale = FlatpickrLanguages["vn"];
    }
    this.getCountInout();
  },
  methods: {
    changeBranch() {
      this.getDepartment();
      this.departmentId = null;
      this.getPosition();
      this.positionId = null;
    },
    async getListBranch() {
      let res;
      try {
        res = await apiServer.post('/api/v1/company/get-all-branch', {});
        if (res && res.data) {
          this.branchs.push({ value: null, text: this.$t('label.allBranches') });
          for (let br of res.data) {
            this.branchs.push({ value: br.placeID, text: br.branch_name });
          }
        }
      }
      catch (e) {
        console.log(e);
      }
    },
    async getDepartment() {
      this.departmentOption = []
      this.departments = []
      let res
      try {
        res = await apiServer.post('api/v1/department/list', {
          placeId: this.placeId
        })
      } catch (error) { }
      if (res && res.data) {
        this.departmentOption = res.data;
        this.departments = res.data;
        console.log(res.data)
      }
    },
    async getPosition() {
      let res
      try {
        res = await apiServer.post('api/v1/position/list', {
          placeId: this.placeId
        })
      } catch (error) { }
      if (res && res.data) {
        this.positionOption = [];
        if (res.data.length > 0) {
          this.positionOption = res.data;
        }
      }
    },
    findDepartmentNameById() {
      this.departmentName = '';
      this.departmentUnit = '';
      console.log(this.departments)
      for (let i = 0; i < this.departments.length; i++) {
        const department = this.departments[i];
        if (department.id === this.departmentId) {
          this.departmentName = department.name
          if (this.departmentName[0] >= "0" && this.departmentName[0] <= "9") {
            this.departmentName = this.departmentName.substring(3);
            console.log(this.departmentName)
          }
        }
        const childrenDepartment = department.children?.find(child => child.id === this.departmentId);
        if (department.children && department.children.length > 0 && childrenDepartment) {
          this.departmentName = department.name;
          this.departmentUnit = childrenDepartment.name;
          if (this.departmentName[0] >= "0" && this.departmentName[0] <= "9") {
            this.departmentName = this.departmentName.substring(3);
          }
          if (this.departmentUnit[0] >= "0" && this.departmentUnit[0] <= "9") {
            this.departmentUnit = this.departmentUnit.substring(3);
          }
        }
      }

    },
    async getDataReport(type) {
      this.loading = true;
      let res;
      let timeStart = null;
      let timeEnd = null;
      // await this.getDepartment()
      // console.log(this.departmentId)
      // console.log(this.departments)
      this.findDepartmentNameById();
      if (this.dateSelect !== null && this.dateSelect !== '') {
        let dateArr = this.dateSelect.split(' ');
        timeEnd = this.convertTimestampSendDate(dateArr[0]);
        if (dateArr.length == 1) {
          timeStart = timeEnd;
        }
        else {
          timeStart = this.convertTimestampSendDate(dateArr[2]);
        }

      }

      console.log(this.reportType)

      try {
        let api = '';
        if (this.reportType == 1) {
          api = '/api/v1/report/work-detail';
          this.fileName = 'report-work';
        } else if (this.reportType == 2) {
          api = '/api/v1/report/checkin-detail';
          this.fileName = 'report-checkin';
        } else if (this.reportType == 3) {
          api = '/api/v1/report/checkin-per-day';
          this.fileName = 'report-per-day';
        } else if (this.reportType == 4) {
          api = '/api/v1/report/checkin-month';
          this.fileName = 'report-per-month';
        } else if (this.reportType == 5) {
          api = '/api/v1/report/work-day-detail';
          this.fileName = 'report-work-detail';
        } else if (this.reportType == 6) {
          api = '/api/v1/report/meals-in-month';
          this.fileName = 'meals-in-month';
        } else if (this.reportType == 7) {
          api = '/api/v1/report/detail-checkin';
          this.fileName = 'detail-checkin';
        } else if (this.reportType == 8) {
          api = '/api/v1/report/general-checkin';
          this.fileName = 'general-checkin';
        } else if (this.reportType == 9) {
          api = '/api/v1/report/shift';
          this.fileName = 'shift';
        } else if (this.reportType == 11) {
          api = '/api/v1/report/minute-late-in-month';
          this.fileName = 'lateReport';
        } else if (this.reportType == 12) {
          api = '/api/v1/report/minute-out-early-in-month';
          this.fileName = 'earlyReport';
        } else if (this.reportType == 10) {
          api = '/api/v1/report/leave';
          this.fileName = 'leave';
        }

        res = await apiServer.post(api, {
          placeid: this.placeId,
          fromdate: timeEnd,
          todate: timeStart,
          date: timeStart,
          year: this.year,
          month: this.month,
          departmentid: this.departmentId,
          positionid: this.positionId,
          aliasid: this.aliasId,
          fullname: this.fullName,
          lateearly: this.lateEarly,
          mode: "preview"
        });
      }
      catch (error) {
        this.loading = false;
        console.log(error)
        if (error.response.status == 400) {
          console.log(error.response)
          let responseText = await error.response.data.error;
          console.log(error.response.data.error)
          alert(this.$t(responseText.error));
        }
      }
      if (res && res.data) {
        this.loading = false;
        this.previewData = res.data[0];
        if (this.previewData.length == 1) {
          alert(this.$t('notify.message.noData'));
        } else {
          if (type == "preview") {
            this.modalPreview = true;
          }
        }
      }
    },
    mergeCell(sttCell) {
      if (sttCell) {
        var nextRow = sttCell.parentNode.nextElementSibling;
        if (nextRow) {
          var nextCell = nextRow.querySelector("td:first-child");
          if (nextCell) {
            nextCell.parentNode.removeChild(nextCell);
            sttCell.rowSpan = 2;
            console.log("RowSpan set successfully, and content of the next cell in the next row cleared.");
          } else {
            console.error("Next cell in the next row not found");
          }
        } else {
          console.error("Next row not found");
        }
      } else {
        console.error("Element not found");
      }
    },
    async previewreport() {
      await this.getDataReport("preview");
    },
    async exportReport() {
      this.loading = true;
      let res;
      let timeStart = null;
      let timeEnd = null;

      this.findDepartmentNameById();

      if (this.dateSelect !== null && this.dateSelect !== '') {
        let dateArr = this.dateSelect.split(' ');
        timeEnd = this.convertTimestampSendDate(dateArr[0]);
        if (dateArr.length == 1) {
          timeStart = timeEnd;
        }
        else {
          timeStart = this.convertTimestampSendDate(dateArr[2]);
        }

      }

      try {
        let api = '';
        if (this.reportType == 1) {
          api = '/api/v1/report/work-detail';
          this.fileName = 'report-work';
        } else if (this.reportType == 2) {
          api = '/api/v1/report/checkin-detail';
          this.fileName = 'report-checkin';
        } else if (this.reportType == 3) {
          api = '/api/v1/report/checkin-per-day';
          this.fileName = 'report-per-day';
        } else if (this.reportType == 4) {
          api = '/api/v1/report/checkin-month';
          this.fileName = 'report-per-month';
        } else if (this.reportType == 5) {
          api = '/api/v1/report/work-day-detail';
          this.fileName = 'report-work-detail';
        } else if (this.reportType == 6) {
          api = '/api/v1/report/meals-in-month';
          this.fileName = 'meals-in-month';
        } else if (this.reportType == 7) {
          api = '/api/v1/report/detail-checkin';
          this.fileName = 'detail-checkin';
        } else if (this.reportType == 8) {
          api = '/api/v1/report/general-checkin';
          this.fileName = 'general-checkin';
        } else if (this.reportType == 9) {
          api = '/api/v1/report/shift';
          this.fileName = 'shift';
        } else if (this.reportType == 11) {
          api = '/api/v1/report/minute-late-in-month';
          this.fileName = 'lateReport';
        } else if (this.reportType == 12) {
          api = '/api/v1/report/minute-out-early-in-month';
          this.fileName = 'earlyReport';
        } else if (this.reportType == 10) {
          api = '/api/v1/report/leave';
          this.fileName = 'leave';
        }

        res = await apiServer.post(api, {
          placeid: this.placeId,
          fromdate: timeEnd,
          todate: timeStart,
          date: timeStart,
          year: this.year,
          month: this.month,
          departmentid: this.departmentId,
          positionid: this.positionId,
          aliasid: this.aliasId,
          fullname: this.fullName,
          lateearly: this.lateEarly
        }, {
          responseType: 'blob'
        }).then((response) => {
          this.loading = false;
          const url = URL.createObjectURL(new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', this.fileName)
          document.body.appendChild(link)
          link.click();
        });
      } catch (error) {
        console.log(error);
        if (error.response.status === 400) {
          this.loading = false;
          console.log(error.response.data.text())
          let responseText = await error.response.data.text();
          console.log(JSON.parse(responseText).error)
          alert(this.$t(JSON.parse(responseText).error));
        }
      }
    },
    async exportPdf() {
      await this.getDataReport(null);
      if (this.reportType == 7) {
        if (!this.isMerge2) {
          var targetCell = document.querySelector("#tbl-preview tr:nth-child(2) td:first-child");
          var target1Cell = document.querySelector("#tbl-preview tr:nth-child(1) td:first-child");

          if (targetCell) {
            targetCell.classList.add("text-left");
            target1Cell.classList.add("text-left");
            target1Cell.classList.add("custom-width");
          }
          var currentDate = new Date();
          var day = currentDate.getDate();
          var month = currentDate.getMonth() + 1;
          var year = currentDate.getFullYear();
          var formattedDate = "Đà Nẵng, ngày " + day + ' tháng ' + month + ' năm ' + year;
          document.querySelector("#date").innerHTML = formattedDate;
          this.isMerge2 = true;
        }
      }
      this.generatePDF();

    },
    generatePDF() {
      this.loading = true;
      this.$refs.html2Pdf.generatePdf();
    },
    onPdfProgress(event) {
      if (event == 100) {
        this.loading = false;
        this.modalPreview = false;
      }
    },
    async getSummary() {
      let res
      try {
        res = await apiServer.post('api/v1/dashboard/summary', {
        })
      } catch (error) {
      }
      if (res && res.data) {
        let data = res.data;
        for (let i = 0; i < data.length; i++) {
          if (data[i].title == "accountNumber") {
            this.infoCheckin.accountNumber = data[i].countcol;
          } else if (data[i].title == "checkedin") {
            this.infoCheckin.checkedin = data[i].countcol;
          } else if (data[i].title == "lateNumber") {
            this.infoCheckin.lateNumber = data[i].countcol;
          } else if (data[i].title == "notCheckin") {
            this.infoCheckin.notCheckin = data[i].countcol;
          } else if (data[i].title == "leave") {
            this.infoCheckin.leave = data[i].countcol;
          } else {
            console.log(data[i].title, data[i].countcol);
          }
        }
      }
    },

    async onChangeCode() {
      this.isOpenCodeRes = true;
      let res;
      try {
        res = await apiServer.post('api/v1/user/suggest-employee', {
          placeID: this.$store.getters.placeID,
          keyword: this.aliasId,
          searchType: "code"
        })
      } catch (error) {
      }
      if (res && res.data) {
        this.codeResults = [];
        if (res.data.length > 0) {
          for (let user of res.data) {
            this.codeResults.push({ value: user.aliasID, text: user.fullName });
          }
        }
      }
    },
    setCodeResult(result) {
      this.aliasId = result.value;
      this.isOpenCodeRes = false;
    },

    async onChangeName() {
      this.isOpenNameRes = true;
      let res;
      try {
        res = await apiServer.post('api/v1/user/suggest-employee', {
          placeID: this.$store.getters.placeID,
          keyword: this.fullName,
          searchType: "name"
        })
      } catch (error) {
      }
      if (res && res.data) {
        this.nameResults = [];
        if (res.data.length > 0) {
          for (let user of res.data) {
            this.nameResults.push({ value: user.aliasID, text: user.fullName });
          }
        }
      }
    },
    setNameResult(result) {
      this.fullName = result.text;
      this.isOpenNameRes = false;
    },
    onClickOutside() {
      this.isOpenCodeRes = false;
    },
    handleClickOutside() {
      this.isOpenNameRes = false;
    },
    async getCountInout() {
      let res
      try {
        res = await apiServer.get('api/v1/report/count-inout', {
        })
      } catch (error) {
      }
      // console.log(res)
      if (res && res.data) {
        this.countInoutIINnfos = res.data;
      }
    },

    async getStatistic() {
      let res
      try {
        res = await apiServer.post('api/v1/checkin/statistic', {
        })
      } catch (error) {
      }
      if (res && res.data) {
        // console.debug(res.data);
        //this.series.push({names:'series-1'});
        let checkedIn = [];
        let checkinLate = [];
        let checkoutEarly = [];
        let leaved = [];
        let unCheckin = [];
        for (let v of res.data) {
          this.options.xaxis.categories.push(v.dateId);
          checkedIn.push(v.checkedin);
          checkinLate.push(v.checkin_late);
          checkoutEarly.push(v.checkout_early);
          leaved.push(v.leaved);
          unCheckin.push(v.un_checkin);
        }
        this.series.push({ name: this.$t('label.checkedin'), data: checkedIn });
        this.series.push({ name: this.$t('label.late'), data: checkinLate });
        this.series.push({ name: this.$t('label.comeBackBefore'), data: checkoutEarly });
        this.series.push({ name: this.$t('label.takeABreak'), data: leaved });
        this.series.push({ name: this.$t('label.notCheckin'), data: unCheckin });
      }
    },

    reportTypeChange() {
      this.getDepartment();
      if (this.reportType === 3) {
        this.dateSelect = null;
        this.configDateTimePicker.mode = "single";
      } else {
        this.configDateTimePicker.mode = "range";
        this.leaveTypeHalf = null;
      }
      this.reportByDepart = false;
      this.reportByPos = false;
      this.reportByMonth = false;
      this.reportByDate = true;
      this.reportByUser = false;
      this.reportByLateEarly = false;
      if ([6, 7, 8].includes(this.reportType)) {
        this.reportByDepart = true;
        this.reportByPos = false;
      }
      if ([6, 8].includes(this.reportType)) {
        this.reportByMonth = true;
        this.reportByDate = false;
      }
      if (this.reportType == 7) {
        this.reportByUser = true;
        this.reportByLateEarly = true;
      }
      if ([9, 10].includes(this.reportType)) {
        this.reportByDepart = true;
        this.reportByDate = false;
        if (this.reportType == 10) {
          this.reportByUser = true;
        }
      }
      if ([11, 12].includes(this.reportType)) {
        // this.reportByDepart = true;
        // this.reportByDate = false;
        // if (this.reportType == 10) {
        //   this.reportByUser = true;
        // }
        this.reportByDepart = true;
        this.reportByMonth = true;
        this.reportByDate = false;
      }
    },
    print() {
      const d = new Printd()
      d.print(document.getElementById('print'),
        [`#print table tr td { border: 1px solid #e9ecef;padding: 2px; } #print table tr:first-child td {text-align: center; font-weight: bold}`])
    },
    convertDate(inputDate, format) {
      if (inputDate == undefined || inputDate == null) return '';
      let date = new Date(inputDate)
      if (date !== 'Invalid Date') {
        let day = ("0" + date.getDate()).slice(-2)
        // current month
        let month = ("0" + (date.getMonth() + 1)).slice(-2)
        // current year
        let year = date.getFullYear();
        if (format == 'dd-MM-yyyy') {
          return day + "-" + month + "-" + year
        } else if (format == 'dd/MM/yyy') {
          return day + "/" + month + "/" + year
        } else if (format == 'yyyy/MM/dd') {
          return year + "/" + "/" + month + "/" + day
        } else {
          return year + "-" + month + "-" + day
        }
      }
    },
    convertTimestampToDate(timestamp) {
      return moment(timestamp).format('DD/MM/yyyy')
    },
    convertTimestampSendDate(date) {
      const dateString = date;
      const changedDate = dateString.replace(/(..)\/(..)\/(....)/, '$3-$2-$1');
      var date = new Date(changedDate);
      return moment(date).format('yyyy-MM-DD')
    }
  }
}
</script>

<style scoped lang="scss">
.icon-dashdoard {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 40px;
}

.list-person-in-out--item {
  background: #fff;
  border-radius: 6px;
  padding: 5px 10px;
  display: flex;
  margin-bottom: 15px;

  .list-person-in-out--item__content {
    display: flex;
    width: 85%;
    justify-content: space-between;
    align-content: center;
    padding: 10px 5px 0;

    h3 {
      line-height: 1.5;
      margin-bottom: 0;
    }

    p {
      font-size: 13px;
      margin-bottom: 0;
    }
  }

  .list-person-in-out--item__img {
    width: 15%;
    padding-top: 5px;

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
}

.autocomplete {
  position: relative;
}

.autocomplete-results {
  position: absolute;
  z-index: 999;
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  min-height: 1em;
  max-height: 18em;
  overflow: auto;
  background: #fff;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 2px 4px;
  cursor: pointer;
}

.autocomplete-result:hover {
  background-color: #eeeceb;
}

tr.tbl-footer {
  border: 1px solid #f2f5f8 !important;
}
</style>

<style>
.contain {
  font-size: 14px;
}

/* tr.tbl-footer {
  border: 1px solid #ebeef2 !important;
}

.tbl-footer td:nth-child(n+6) {
  border: none !important;
} */

.sign-table td:nth-child(2) {
  transform: skewX(-15deg);
}

#tbl-preview tr.tbl-footer td[data-v-2b2175c8] {
  border: 1px solid #e2e5e8 !important;
}

.middle-text {
  text-align: center;
  vertical-align: middle;
}

.text-left {
  text-align: left !important;
}

.pdf-content {
  font-size: 12px;
  break-inside: avoid;
}

.table-modal-content {
  max-width: 98vw;
  overflow-x: auto;
}


/* .custom-width {
  width: 50% !important;
} */

/* tr.tbl-footer {
  border: 1px solid black !important;
}
.table-hover tr {
  border: 1px solid black !important;
} */

.modal-fullscreen .modal-dialog {
  max-width: 100%;
  margin: 0;
  left: 0;
  right: 0;
  display: flex;
  position: fixed;
  z-index: 100000;
}

.modal-fullscreen .modal-dialog .modal-body {
  width: 100%;
  height: calc(100vh - 200px);
  overflow: auto;
}

/* // Tree Select fix */
.vue-treeselect--searchable:not(.vue-treeselect--disabled) .vue-treeselect__value-container {
  padding: 9px !important;
}

/* .vue-treeselect__single-value {
    padding: 7px;
} */
</style>

<template>
  <!-- Page content -->
  <div class="container-fluid mt-3">
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-header border-0">
            <div class="row align-items-center justify-content-center">
              <div class="col-md-4 col-xs-12 mb-2" v-if="isSupperAdmin || isComAdmin">
                <label>{{$t('label.branch')}}</label>
                <select v-model="branchid" class="form-control">
                    <option v-for="branch in optionBranch" v-bind:value="branch.value">
                        {{ branch.text }}
                    </option>
                </select>
              </div>
              <div class="col-md-4 col-xs-12 mb-2">
                <label>{{$t('label.department')}}</label>
                <treeselect v-model="department" :multiple="true" :options="dpoptions" :flat="true"
                          :default-expand-level="1" :placeholder="$t('label.chooseDepartment')" />
              </div>
              <!-- <div class="col-md-4 col-xs-12 mb-2">
                <label>{{$t('label.nameEmployee')}}, {{$t('label.employeeCode')}}, email</label>
                <input
                  type="text"
                  v-model="namePerson"
                  class="form-control"
                />
              </div>
              <div class="col-md-4 col-xs-12 mb-2">
                <label>{{$t('label.object')}}</label>
                <select class="form-control" v-model="modelObject">
                  <option
                    v-for="optionsObjectItem in optionsObject"
                    v-bind:value="optionsObjectItem.value"
                  >
                    {{ optionsObjectItem.text }}
                  </option>
                </select>
              </div>-->
     
              <div class="col-md-4 col-xs-12 mb-2">
                <label>{{ $t('label.start') }}</label>
                <flat-pickr v-model="startDateString" :config="configDateTimePicker"
                            name="birthdate"
                            class="form-control"></flat-pickr>
              </div>
              <div class="col-md-4 col-xs-12 mb-2">
                <label>{{ $t('label.end') }}</label>
                <flat-pickr v-model="endDateString" :config="configDateTimePicker"
                            name="birthdate"
                            class="form-control"></flat-pickr>
              </div>
              <div class="col-md-4 col-xs-12 mb-2">
                <label>{{$t('label.selectCamera')}}</label>
                <select class="form-control" v-model="modelDevice">
                  <option
                    v-for="optionsDeviceItem in optionsDevice"
                    v-bind:value="optionsDeviceItem.value"
                  >
                    {{ optionsDeviceItem.text }}
                  </option>
                </select>
              </div> 
              <div class="col-md-4  col-xs-12 mb-2">
                <b-button variant="btn btn-info btn-outline-primary w-100 " style="margin-top: 29px;"  @click="search()">{{$t('label.search')}}
                </b-button>
              </div>
            </div>
          </div>
          <div class="number-records"><span>{{$t('label.totalRecord')}}{{totalRecord}}</span>
            <button class="btn btn-sm btn-primary float-right mr-3 mb-2"
                    @click="exportInOutHistory()">
              <font-awesome-icon icon="download" />
              {{ $t('label.exportReport') }}
            </button>
          </div>
          <div class="row mt-3">
            <div class=" col-xs-12 col-md-6">
              <div class="card">
                <div class="card-body m-0 p-0 table-responsive">
                  <b-table
                    bordered="bordered"
                    class=" default-table"
                    hover
                    :items="dataTable"
                    :fields="fields2"
                    responsive
                    id="table-staff"
                    @row-clicked="rowClickHandler"
                  >
                  </b-table>
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRecord"
                    :per-page="perPage"
                    align="right"
                    size="sm"
                    class="m-4"
                    aria-controls="table-staff"
                    v-on:click.native="getCheckinByPlaceIdInTimestamp()"
                  ></b-pagination>
                </div>
              </div>
            </div>
            <div class=" col-xs-12 col-md-6">
            <b-table
              striped bordered
              class="default-table"
              hover
              :items="dataTableHistory"
              :fields="fields"
              responsive
              id="table-inouthistory"
              :busy="isBusy"
              show-empty
            >
            <template #cell(avatar)="data">
                      <a
                        href="javascript:void(0)"
                        @click="showImage(data.item.avatar)"
                      >
                      <img
                        v-if="data.item.avatar"
                        width="95px"
                        height="95px"
                        :src="data.item.avatar"
                        :alt="data.item.personName"
                    />
                    <img
                        v-else
                        width="95px"
                        height="95px"
                        src="../../assets/img/avtDefault.jpg"
                        :alt="data.item.personName"
                    />
                      </a>
                    </template>
              <!-- <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template #cell(personType)="data">
                <span v-if="data.item.personType == 0">{{$t('label.employee')}}</span>
                <span v-else>{{$t('label.stranger')}}</span>
              </template>
                <template #cell(personName)="data">
                  <span v-if="data.item.personType == 0">{{data.item.personName}}</span>
                  <span v-else></span>
                </template>
              <template #cell(image)="data">
                <a href="javascript:void(0)" @click="showImage(data.item.avatar)">
                  <img width="95px" height="95px" :src="data.item.avatar" :alt="data.item.personName"/>
                </a>
              </template>
              <template #cell(checkinTime)="data">
                {{ convertTimestampToDate(data.item.checkinTime) }}
              </template>
              <template #empty="scope">
                {{$t('notify.message.nodata')}}
              </template> -->
              <!-- <b-pagination
            v-model="currentPage"
            :total-rows="totalRecord"
            :per-page="perPage"
            align="right"
            size="sm"
            class="m-4"
            aria-controls="table-inouthistory"
            v-on:click.native="search()"
          ></b-pagination> -->
            </b-table>
            
            <!-- <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRecord"
                    :per-page="perPage"
                    align="right"
                    size="sm"
                    class="m-4"
                    aria-controls="table-busy"
                  ></b-pagination> -->
                  <b-pagination
                    v-model="currentPageHistory"
                    :total-rows="dataTableHistory.length"
                    :per-page="perPageHistory"
                    align="right"
                    size="sm"
                    class="m-4"
                    aria-controls="table-inouthistory"
                    v-on:click.native="rowClickHandler()"
                  ></b-pagination>
            </div>
          </div>

         
        </div>
      </div>
    </div>
    <b-modal v-model="modalImage" hide-footer centered hide-header modal-class="modal-image inouthistory" size="lg" >
      <div class="btn-close"><span @click="hideImage()">x</span></div>
      <div class="d-block text-center">
        <img :src="urlImage" style="width:530px;padding:15px"/>
      </div>
    </b-modal>
    <pulse-loader :loading="loading"></pulse-loader>
  </div>
</template>
<script>
import apiClient from '@/axios'
import apiServer from '@/axiosServer'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import moment from 'moment'
import FlatpickrLanguages from "flatpickr/dist/l10n"
import PulseLoader from '@/components/PulseLoader.vue'
import Treeselect from '@riophae/vue-treeselect'

export default {
  components: {
    flatPickr,
    PulseLoader,
    Treeselect
  },
  data () {
    return {
      loading: false,
      isSupperAdmin: false,
      isComAdmin: false,
      urlImage: '',
      modalImage: false,
      department: null,
      dpoptions: [],
      isBusy: true,
      modelObject: '',
      startDateString: '',
      endDateString: '',
      deviceString: '',
      sort: '',
      nowDate: '',
      configDateTimePicker: {
        enableTime: true,
        dateFormat: 'd/m/Y H:i:ss',
        time_24hr: true,
        locale: FlatpickrLanguages["en"],
        shorthandCurrentMonth: true
      },
      optionsObject: [
        {
          value: '',

          text: this.$t('label.all')
        },
        {
          value: '0',
          text: this.$t('label.employee')
        },
        {
          value: '1',
          text: this.$t('label.guest')
        },
        {
          value: '4',
          text: this.$t('label.stranger')
        }
      ],
      dataTableHistory: [],
      modelDevice: '',
      optionsDevice: [
        {
          value: '',
          text: this.$t('label.all')
        }
      ],
      branchid: null,
      optionBranch: [],
      dataTable: [],
      totalRecord: 0,
      currentPageHistory: 1,
      currentPage: 1,
      perPage: 25,
      perPageHistory: 25,
      placeId: 0,
      fields: [
        {
          label: this.$t('label.device'),
          key: 'deviceName',
          class: "align-middle",
          sortable: false
        },
        {
          label: this.$t('label.image'),
          key: 'avatar',
          class: "align-middle text-center",
          sortable: false
        },
        {
          label: this.$t('label.inout'),
          key: 'inout',
          class: "align-middle text-center",
          sortable: false
        },
        {
          label: this.$t('label.time'),
          key: 'checkinTime',
          class: "align-middle",
          sortable: true
        }
      ],
      fields2: [
        {
          label: this.$t('label.employeeCode'),
          key: 'aliasID',
          class: 'align-middle',
          sortable: false
        },
        {
          label: this.$t('label.fullName'),
          key: 'personName',
          class: 'align-middle',
          sortable: false
          ,
          formatter: (value) => {
            return value ? value : 'Khách';
        }
        },
        {
          label: this.$t('label.department'),
          key: 'department',
          class: 'align-middle',
          sortable: false
        }
      ],
      accessToken: process.env.VUE_APP_TOKEN_HANET,
      namePerson: '',
    }
  },
  mounted () {
    this.isSupperAdmin = this.$store.getters.superAdmin;
    this.isComAdmin = this.$store.getters.comAdmin;
    if (this.isSupperAdmin || this.isComAdmin) {
      this.getBranch();
    } else {
      this.getListDeviceByPlace();
    }
    this.getDepartmentTree();
    this.startDateString = this.getNowDate() + ' 00:00:00'
    this.endDateString = this.getNowDate() + ' 23:59:00'
    this.getCheckinByPlaceIdInTimestamp()
    if(this.$store.getters.locale == 'vi'){
      this.configDateTimePicker.locale = FlatpickrLanguages["vn"]
    };
  },
  methods: {
    hideImage () {
      this.modalImage = false
    },
    showImage (urlImage) {
      this.urlImage = urlImage
      this.modalImage = true
    },
    async getCheckinByPlaceIdInTimestamp () {
      this.loading = true;
      let res;
      if (this.department && this.department.length == 0){
        this.department = null;
      }
      try {
        this.dataTable = [];
        res = await apiServer.post('api/v1/camera/get-in-out-history', {
          placeID: this.$store.getters.placeID,
          toDate:  this.convertTimestampSendDate(this.endDateString),
          fromDate: this.convertTimestampSendDate(this.startDateString),
          device: this.modelDevice,
          objectType: this.modelObject,
          branchId: this.branchid,
          department: this.department,
          sortTime: "desc",
          userName: this.namePerson,
          pageIndex: this.currentPage,
          rowPerPage: this.perPage
        })
      } catch (error) {
        this.loading = false;
      }
      if (res && res.data) {
        this.dataTable = res.data.items;
        this.totalRecord = res.data.totalRecord;
        console.log(this.totalRecord);
        this.isBusy = false;
        this.loading = false;
      }
    },
    async getListDeviceByPlace () {
      let res
      try {
        res = await apiServer.post('api/v1/device/list',
        {})
      } catch (error) {
      }
      if (res && res.data) {
        const arrayData = res.data
        this.optionsDevice = [
          {
            value: '',
            text: this.$t('label.all')
          }
        ]
        for (let i = 0; i < arrayData.length; i++) {
          this.deviceString += arrayData[i].deviceID + ','
          this.optionsDevice.push({
            value: arrayData[i].deviceID,
            text: arrayData[i].deviceName
          })
        }
      } else {
        this.optionsDevice = [
          {
            value: '',
            text: this.$t('label.all')
          }
        ]
        this.isBusy = false
      }
    },
    async getBranch () {
      let res
      try {
        res = await apiServer.post('api/v1/company/get-all-branch', {
        })
      } catch (error) {}
      if (res && res.data) {
        this.optionBranch = [];
        if (res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            this.optionBranch.push({
              text: res.data[i].branch_name,
              value: res.data[i].placeID
            })
          }
        }
      }
    },
    async getDepartmentTree(){
      let res
      try {
        res = await apiServer.get('api/v1/department/tree', {})
      } catch (error) {
      }
      if (res && res.data) {
        this.dpoptions = [res.data];
      }
    },
    async getShiftHistory(uid){
      this.dataTableHistory = [];
      let res
      try {
        res = await apiServer.post('api/v1/camera/get-detail-in-out-history', {
          userId: uid,
          currentPage: this.currentPage,
          rowPerPage: this.perPage,
        })
      } catch (error) {}
      if (res && res.data) {
        this.dataTableHistory = res.data.items;
        console.log(this.dataTableHistory)
      }
    },
    async exportInOutHistory () {
      this.loading = true;
      let res
      try {
        res = await apiServer.post('api/v1/camera/export-in-out-history', {
          placeID: this.$store.getters.placeID,
          toDate:  this.convertTimestampSendDate(this.startDateString),
          fromDate: this.convertTimestampSendDate(this.endDateString),
          device: this.modelDevice,
          objectType: this.modelObject,
          sortTime: "desc",
          userName: this.namePerson
        },{
          responseType: 'blob'
        }).then((response) => {
          this.loading = false;
          const url = URL.createObjectURL(new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'in-out-history')
          document.body.appendChild(link)
          link.click();
        });
      } catch (error) {
        this.loading = false;
      }
    },
    search () {
      this.getCheckinByPlaceIdInTimestamp()
    },
    getNowDate () {
      let today = new Date()
      const dd = String(today.getDate()).padStart(2, '0')
      const mm = String(today.getMonth() + 1).padStart(2, '0')
      const yyyy = today.getFullYear()
      today = dd + '/' + mm + '/' + yyyy
      return today
    },
    convertTimestampToDate (timestamp) {
      return moment(timestamp).format('DD/MM/yyyy HH:mm:ss')
    },
    convertTimestampSendDate (date) {
      if (date == undefined || date == null){
        return null;
      }
      const dateString = date;
      const changedDate = dateString.replace(/(..)\/(..)\/(....) (..):(..)/, '$3-$2-$1 $4:$5');
      var date = new Date(changedDate);
      return moment(date.getTime()).format('yyyy-MM-DD HH:mm:ss')
    },

    convertDateToTimestamp (date) {
      if (date == null) return ''
      const newDate = new Date(date)
      return newDate.getTime()
    },
    rowClickHandler(record, index) {
      this.getShiftHistory(record.id);
    }
  }
}
</script>
<style lang="scss">
.number-records{
  padding-left: 1.25rem;
  padding-bottom: 0.25rem
}
.modal-image{
  img{
    width: 100%;
    height: 100%;
  }
}
</style>

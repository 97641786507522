<template>
  <!-- Page content -->
  <div id="main-default" class="container-fluid">
    <div class="card col-xl-12">
      <div class="row">
        <div class="col-xl-12">
          <div class="row">
            <div class="col-md-6 col-xs-12">
              <h1 class="page-title mt-2">
                {{ $t("title.managementLeave") }}
              </h1>
              <p class="text-muted">
                {{ $t("label.totalLeave") }} {{ dataTable.length }}
              </p>
            </div>
            <div class="col-md-6 col-xs-12 text-right">
              <div class="mt-4">
                <a
                  href="javascript:void(0)"
                  @click="showModalAddLeave()"
                  class="btn btn-danger bg-gradient-danger"
                >
                  <font-awesome-icon icon="plus" />
                  {{ $t("label.addNew") }}</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-12">
          <div class="row">
            <div class="col-xs-12 col-md-3">
              <input
                type="text"
                :placeholder="$t('label.staffsName')"
                class="form-control"
                v-model="userName"
              />
            </div>
            <div class="col-xs-12 col-md-3" v-if="isSupperAdmin || isComAdmin">
              <b-form-group>
                <select v-model="idBranch" class="form-control">
                  <option v-for="branch in branchs" v-bind:value="branch.value">
                    {{ branch.text }}
                  </option>
                </select>
              </b-form-group>
            </div>
            <div class="col-xs-12 col-md-3">
              <b-form-group>
                <select v-model="status" class="form-control">
                  <option
                    v-for="status in listStatus"
                    v-bind:value="status.value"
                  >
                    {{ status.text }}
                  </option>
                </select>
              </b-form-group>
            </div>
            <div class="col-md-3 col-xs-12">
              <b-button
                variant="btn btn-info  btn-outline-primary w-100"
                @click="search('search')"
                >{{ $t("label.search") }}
              </b-button>
            </div>
          </div>
        </div>
        <div class="col-xl-12">
          <div class="row mt-3">
            <div class="col-xl-12 col-xs-12 text-right mb-2">
              <button
                class="btn btn-primary mr-2 button-mb"
                @click="downloadFileTemplate()"
              >
                <font-awesome-icon icon="download" />
                {{ $t("label.downloadFileTemplate") }}
              </button>
              <b-form-file
                style="width: auto; text-align: left"
                :placeholder="$t('label.importLeaveFromFile')"
                drop-placeholder="Drop file here..."
                accept=".xlsx, .xls"
                ref="file"
              ></b-form-file>
              <b-button
                variant="btn btn-primary ml-2 button-mb"
                @click="importLeave()"
                >{{ $t("label.import") }}
              </b-button>
            </div>
            <div class="col-xl-12">
              <div class="card">
                <div class="card-body m-0 p-0 table-responsive">
                  <b-table
                    bordered="bordered"
                    class="default-table"
                    hover
                    :items="dataTable"
                    :fields="fields"
                    responsive
                    :current-page="currentPage"
                    :per-page="perPage"
                    id="table-leave"
                    @row-clicked="showInfoDetail"
                    show-empty
                  >
                    <template #cell(infoUser)="data">
                      <div class="row">
                        <div class="col-2 p-0">
                          <img
                            :src="data.item.avatar"
                            alt=""
                            height="90px"
                            width="80px"
                          />
                        </div>
                        <div class="col-10">
                          <small class="text-muted">{{
                            data.item.personID
                          }}</small>
                          <p class="m-0">{{ data.item.fullName }}</p>
                          <small class="text-muted">{{
                            data.item.email
                          }}</small>
                        </div>
                      </div>
                    </template>
                    <template #cell(browser)="data">
                      <p class="m-0">{{ data.item.userApprove }}</p>
                    </template>
                    <template #cell(reason)="data">
                      <div class="m-0">
                        {{ data.item.reason | truncate(40, "...") }}
                      </div>
                      <small class="text-danger"
                        >{{ $t("label.timeDay") }}:
                        <template
                          v-if="
                            data.item.off_work_from != data.item.off_work_to
                          "
                        >
                          {{
                            convertDate(data.item.off_work_from) +
                            " - " +
                            convertDate(data.item.off_work_to)
                          }}
                        </template>
                        <template v-else>
                          {{ convertDate(data.item.off_work_to) }}
                        </template>
                      </small>
                    </template>
                    <template #cell(status)="data">
                      <div class="m-0 text-white">
                        <template v-if="data.item.approved == 1">
                          <span
                            style="
                              background-color: rgb(45 189 76);
                              border-radius: 10px;
                              line-height: 20px;
                              font-weight: bold;
                              padding: 3px 10px;
                              font-size: 13px;
                            "
                            >{{ $t("label.agree") }}</span
                          >
                        </template>
                        <template v-else-if="data.item.approved == 2">
                          <span
                            style="
                              background-color: lightgreen;
                              border-radius: 10px;
                              line-height: 20px;
                              font-weight: bold;
                              padding: 3px 10px;
                              font-size: 13px;
                            "
                            >{{ $t("label.pending") }}</span
                          >
                        </template>
                        <template v-else-if="data.item.approved == 3">
                          <span
                            style="
                              background-color: red;
                              border-radius: 10px;
                              line-height: 20px;
                              font-weight: bold;
                              padding: 3px 10px;
                              font-size: 13px;
                            "
                            >{{ $t("label.refuse") }}</span
                          >
                        </template>
                        <template v-else>
                          <span
                            style="
                              background-color: rgb(251 99 64);
                              border-radius: 10px;
                              line-height: 20px;
                              font-weight: bold;
                              padding: 3px 10px;
                              font-size: 13px;
                            "
                            >{{ $t("label.waitingForApprove") }}</span
                          >
                        </template>
                      </div>
                    </template>
                    <template #empty="scope">
                      {{ $t("notify.message.nodata") }}
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="dataTable.length"
                    :per-page="perPage"
                    align="right"
                    size="sm"
                    class="m-4"
                    aria-controls="table-leave"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      v-model="modalDelete"
      ref="modal-delete"
      hide-header
      hide-footer
      header-bg-variant="secondary"
      modal-class="modal-delete text-center"
      centered
      size="md"
    >
      <div>
        <div class="modal-delete--warning">
          <div class="modal-delete--warning__content">!</div>
        </div>
        <div>
          <h2 class="text-warning">{{ $t("label.areYouSure") }}?</h2>
        </div>
        <div>{{ $t("label.sureLeave") }}</div>
        <div class="mt-3">
          <b-button variant="primary" @click="deleteLeave(idDelete)"
            >{{ $t("label.iAgree") }}
          </b-button>
          <b-button variant="secondary" @click="hideModalDelete"
            >{{ $t("label.cancel") }}
          </b-button>
        </div>
      </div> </b-modal
    ><!-- modal delete  -->
    <b-modal
      v-model="modalApproval"
      id="modal-approval"
      hide-header
      hide-footer
      header-bg-variant="secondary"
      modal-class="modal-delete text-center"
      centered
      size="md"
    >
      <div>
        <div class="modal-delete--warning">
          <div class="modal-delete--warning__content">!</div>
        </div>
        <div>
          <h2 class="text-warning">{{ $t("label.areYouSure") }}?</h2>
        </div>
        <div>{{ $t("label.sureApproveLeave") }}</div>
        <div class="mt-3">
          <b-button variant="primary" @click="approvalLeave(1)"
            >{{ $t("label.iAgree") }}
          </b-button>
          <b-button variant="primary" @click="deniedLeave()"
            >{{ $t("label.refuse") }}
          </b-button>
          <b-button variant="secondary" @click="$bvModal.hide('modal-approval')"
            >{{ $t("label.cancel") }}
          </b-button>
        </div>
      </div> </b-modal
    ><!-- modal modalApproval  -->
    <b-modal
      v-model="modalAddLeave"
      ref="modal-add-leave"
      :title="$t('label.addLeaveApplication')"
      hide-footer
      header-bg-variant="secondary"
      centered
      size="xl"
    >
      <b-form ref="form" class="row">
        <div class="col-lg-6 col-md-6 col-xs-12">
          <b-form-group
            id="input-group-1"
            class="required-mess"
            :label="$t('label.selectStaff')"
          >
            <v-select
              v-model="idPerson"
              :options="optionsIdPerson"
              @option:selected="selectPerson"
              label="text"
              :filterable="false"
              @search="changeIdPerson"
            >
              <template slot="no-options" v-if="!notFound">
                {{ $t("label.pleaseEnterEmployeeName") }}
              </template>
              <template slot="no-options" v-if="notFound">
                {{ $t("error.message.userNotFound") }}
              </template>
              <template v-slot:option="option">
                <div class="row show-user-list">
                  <div class="col-2"><img :src="option.avatar" alt="" /></div>
                  <div class="col-10">
                    <h5>{{ option.text }}</h5>
                    <p>{{ option.email }}</p>
                  </div>
                </div>
              </template>
            </v-select>
            <div class="error-message w-100 clear text-left">
              {{ msg["userId"] ? $t(msg["userId"]) : "" }}
            </div>
          </b-form-group>
        </div>
        <div class="col-lg-6 col-md-6 col-xs-12">
          <b-form-group
            class="required-mess"
            id="input-group-1"
            :label="$t('label.leaveType')"
          >
            <select v-model="leaveType" class="form-control">
              <option v-for="lt in leaveTypes" v-bind:value="lt.value">
                {{ lt.text }}
              </option>
            </select>
            <div class="error-message w-100 clear text-left">
              {{ msg["leaveType"] ? $t(msg["leaveType"]) : "" }}
            </div>
          </b-form-group>
        </div>
        <div class="col-lg-6 col-md-6 col-xs-12">
          <b-form-group
            id="input-group-2"
            class="required-mess"
            :label="$t('label.chooseDate')"
          >
            <flat-pickr
              v-model="dateSelect"
              :config="configDateTimePicker"
              :placeholder="$t('label.chooseDate')"
              name="reportDate"
              class="form-control"
            ></flat-pickr>
            <div class="error-message w-100 clear text-left">
              {{ msg["offWorkFrom"] ? $t(msg["offWorkFrom"]) : "" }}
            </div>
          </b-form-group>
        </div>
        <div class="col-lg-6 col-md-6 col-xs-12">
          <b-form-checkbox
            class="b-form-checkbox"
            v-model="leaveHalfShift"
            @change="setLeaveHalf($event)"
            >{{ $t("label.leaveHalfShift") }}</b-form-checkbox
          >
          <b-form-group
            :label="$t('label.chooseLeaveHalf')"
            v-show="leaveHalfShift"
          >
            <select class="form-control" v-model="leaveTypeHalf">
              <option value="1">{{ $t("label.leaveFirstHalf") }}</option>
              <option value="2">{{ $t("label.leaveSecondHalf") }}</option>
            </select>
            <div class="error-message w-100 clear text-left">
              {{ msg["leaveHalf"] ? $t(msg["leaveHalf"]) : "" }}
            </div>
          </b-form-group>
        </div>
        <div class="col-lg-6 col-md-6 col-xs-12">
          <b-form-group
            id="input-group-2"
            :label="$t('label.reasonForLeave')"
            class="required-mess"
          >
            <textarea
              class="form-control form-control-sm"
              id="tar-2"
              :placeholder="$t('label.reasonForLeave')"
              required
              v-model="reasonForLeave"
              rows="3"
            ></textarea>
            <div class="error-message w-100 clear text-left">
              {{ msg["reason"] ? $t(msg["reason"]) : "" }}
            </div>
          </b-form-group>
        </div>
        <div class="row col-12" v-for="(approval, i) in approveList" :key="i">
          <div class="col-12">
            <h3>{{ $t("label.approveStep") }} {{ i + 1 }}</h3>
          </div>
          <div class="col-6">
            <b-form-group :label="$t('label.jobPosition')">
              <treeselect
                v-model="approval.position"
                :multiple="false"
                :options="approval.positionOption"
                :default-expand-level="Infinity"
                @select="changePosition(i)"
                :noChildrenText="$t('label.noNodeChildren')"
                :placeholder="$t('label.choosePosition')"
              />
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group :label="$t('label.approver')">
              <select class="form-control" v-model="approval.user">
                <option
                  v-for="user in approval.listUser"
                  v-bind:value="user.id"
                >
                  {{ user.fullName }}
                </option>
              </select>
              <div class="error-message w-100 clear text-left">
                {{ msg["approveUser" + i] ? $t(msg["approveUser" + i]) : "" }}
              </div>
            </b-form-group>
          </div>
        </div>
        <div class="col-12">
          <b-button @click="addMoreApprove">{{
            $t("label.addApproveStep")
          }}</b-button>
        </div>
      </b-form>
      <div class="d-block text-center">
        <div class="text-center">
          <b-button class="btn btn-danger" @click="addLeave"
            >{{ $t("label.save") }}
          </b-button>
          <b-button class="btn btn-default" @click="hidemodalAddLeave"
            >{{ $t("label.cancel") }}
          </b-button>
        </div>
      </div>
    </b-modal>
    <!-- modal addLeave  -->

    <b-modal
      v-model="modalInfoDetail"
      ref="modal-info-detail"
      hide-header
      hide-footer
      header-bg-variant="secondary"
      modal-class="modal-info-detail text-center"
      centered
      size="lg"
    >
      <div>
        <div>
          <table class="table table-bordered">
            <thead class="thead-light">
              <tr>
                <th style="text-transform: none; font-size: 18px">
                  {{ $t("label.sender") }}
                </th>
                <th style="text-transform: none; font-size: 18px">
                  {{ $t("label.browser") }}
                </th>
              </tr>
            </thead>
            <tr class="text-left">
              <td>
                <p>{{ infoSenderDetail.fullName }}</p>
                <p>{{ infoSenderDetail.email }}</p>
              </td>
              <td>
                <table>
                  <tr>
                    <th>{{ $t("label.browser") }}</th>
                    <th>{{ $t("label.status") }}</th>
                    <th>{{ $t("label.approveStep") }}</th>
                    <th>{{ $t("label.content") }}</th>
                  </tr>
                  <tr v-for="(item, index) in infoSenderDetail.listApprover">
                    <td>{{ item.fullName }}</td>
                    <td>{{ getStatus(item.approve_status) }}</td>
                    <td>{{ item.approve_step }}</td>
                    <td>{{ item.approve_content }}</td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr class="text-left">
              <td colspan="2">
                <b>{{ $t("label.status") }}: </b>
                <template v-if="infoSenderDetail.approved == 1">
                  <span
                    style="
                      background-color: rgb(45 189 76);
                      border-radius: 10px;
                      line-height: 20px;
                      font-weight: bold;
                      padding: 3px 10px;
                      font-size: 13px;
                      color: #fff;
                    "
                    >{{ $t("label.agree") }}</span
                  >
                </template>
                <template v-else-if="infoSenderDetail.approved == 2">
                  <span
                    style="
                      background-color: lightgreen;
                      border-radius: 10px;
                      line-height: 20px;
                      font-weight: bold;
                      padding: 3px 10px;
                      font-size: 13px;
                      color: #fff;
                    "
                    >{{ $t("label.pending") }}</span
                  >
                </template>
                <template v-else-if="infoSenderDetail.approved == 3">
                  <span
                    style="
                      background-color: red;
                      border-radius: 10px;
                      line-height: 20px;
                      font-weight: bold;
                      padding: 3px 10px;
                      font-size: 13px;
                      color: #fff;
                    "
                    >{{ $t("label.refuse") }}</span
                  >
                </template>
                <template v-else>
                  <span
                    style="
                      background-color: rgb(251 99 64);
                      border-radius: 10px;
                      line-height: 20px;
                      font-weight: bold;
                      padding: 3px 10px;
                      font-size: 13px;
                      color: #fff;
                    "
                    >{{ $t("label.waitingForApprove") }}</span
                  >
                </template>
              </td>
            </tr>
            <thead class="thead-light">
              <tr>
                <th colspan="2" style="text-transform: none; font-size: 18px">
                  {{ $t("label.reasonForLeave") }}
                </th>
              </tr>
            </thead>
            <tr class="text-left">
              <td colspan="2">
                <p>
                  <strong>{{ $t("label.timeDay") }}:</strong>
                  <template
                    v-if="
                      infoSenderDetail.off_work_from !=
                      infoSenderDetail.off_work_to
                    "
                  >
                    {{
                      convertDate(
                        infoSenderDetail.off_work_from,
                        "dd/MM/yyyy"
                      ) +
                      " - " +
                      convertDate(infoSenderDetail.off_work_to, "dd/MM/yyyy")
                    }}
                  </template>
                  <template v-else>
                    {{
                      convertDate(infoSenderDetail.off_work_to, "dd/MM/yyyy")
                    }}
                  </template>
                </p>
                <p>
                  <strong>{{ $t("label.reason") }}:</strong>
                  {{ infoSenderDetail.reason }}
                </p>
              </td>
            </tr>

            <thead class="thead-light" v-if="infoSenderDetail.approved == 3">
              <tr>
                <th colspan="2" style="text-transform: none; font-size: 18px">
                  {{ $t("label.reasonDenied") }}
                </th>
              </tr>
            </thead>
            <tr class="text-left" v-if="infoSenderDetail.approved == 3">
              <td colspan="2">
                <p>
                  <strong>{{ $t("label.reason") }}:</strong>
                  {{ infoSenderDetail.reason_denied }}
                </p>
              </td>
            </tr>
          </table>
        </div>

        <div class="mt-3">
          <b-button variant="secondary" @click="hideModalInfoDetail"
            >{{ $t("label.close") }}
          </b-button>
        </div>
      </div> </b-modal
    ><!-- modal show Info Detail  -->
    <b-modal
      id="bv-modal-denied"
      v-model="modalDenied"
      ref="modal-approval"
      hide-header
      hide-footer
      centered
      size="md"
    >
      <b-form>
        <b-form-group
          id="input-group-2"
          :label="$t('label.reasonDenied')"
          label-for="dn-2"
        >
          <textarea
            class="form-control form-control-sm"
            id="dn-2"
            :placeholder="$t('label.reasonDenied')"
            required
            v-model="reasonDenied"
            rows="3"
          ></textarea>
          <div class="error-message w-100 clear text-left">
            {{ msg["reason"] ? $t(msg["reason"]) : "" }}
          </div>
        </b-form-group>
      </b-form>
      <div class="d-block text-center">
        <div class="text-center">
          <b-button class="btn btn-danger" @click="approvalLeave(2)"
            >{{ $t("label.save") }}
          </b-button>
          <b-button
            class="btn btn-default"
            @click="$bvModal.hide('bv-modal-denied')"
            >{{ $t("label.cancel") }}
          </b-button>
        </div>
      </div>
    </b-modal>
    <pulse-loader :loading="loading"></pulse-loader>
  </div>
</template>
<script>
import apiClient from "@/axios";
import apiServer from "@/axiosServer";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
import FlatpickrLanguages from "flatpickr/dist/l10n";
import PulseLoader from "@/components/PulseLoader.vue";
import Treeselect from "@riophae/vue-treeselect";
export default {
  components: {
    flatPickr,
    PulseLoader,
    Treeselect,
  },
  data() {
    return {
      isSupperAdmin: false,
      isComAdmin: false,
      loading: false,
      modalDelete: false,
      modalInfoDetail: false,
      dataTable: [],
      showDeleteSuccess: false,
      currentPage: 1,
      perPage: 25,
      totalTakeLeave: 5,
      showApprovalSuccess: false,
      modalApproval: false,
      modalAddLeave: false,
      fields: [
        {
          label: this.$t("label.staffsName"),
          key: "infoUser",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t("label.leaveType"),
          key: "name",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t("label.browser"),
          key: "browser",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t("label.reason"),
          key: "reason",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t("label.status"),
          key: "status",
          class: "align-middle",
          sortable: false,
        },
      ],
      infoSenderDetail: [],
      editLeave: [],
      addNewsuccess: false,
      addNewError: false,
      reasonForLeave: "",
      idPerson: "",
      leaveHalfShift: false,
      leaveTypeHalf: null,
      optionsIdPerson: [],
      dateSelect: "",
      configDateTimePicker: {
        dateFormat: "d/m/Y",
        mode: "range",
        minDate: "today",
        locale: FlatpickrLanguages["en"],
        shorthandCurrentMonth: true,
        onClose: function (selectedDates, dateStr, instance) {
          if (selectedDates.length == 1) {
            instance.setDate([selectedDates[0], selectedDates[0]], true);
          }
        },
      },
      options: [],
      idBranch: null,
      branchs: [],
      status: null,
      listStatus: [
        { text: this.$t("label.status"), value: null },
        { text: this.$t("label.waitingForApprove"), value: 0 },
        { text: this.$t("label.approved"), value: 1 },
        { text: this.$t("label.pending"), value: 2 },
        { text: this.$t("label.refuse"), value: 3 },
      ],
      userName: "",
      msg: [],
      modalDenied: false,
      reasonDenied: "",
      leaveType: null,
      leaveTypes: [],
      branchid: null,
      listPosition: [],
      approveList: [],
      notFound: false,
    };
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        text = text.substring(0, length);
        text = text.substring(0, text.lastIndexOf(" "));
        return text + suffix;
      } else {
        return text;
      }
    },
  },
  mounted() {
    if (this.$store.getters.superAdmin == true) {
      this.isSupperAdmin = true;
    }
    if (this.$store.getters.comAdmin == true) {
      this.isComAdmin = true;
    }
    if (this.isSupperAdmin || this.isComAdmin) {
      this.getListBranch();
    } else {
      this.getListLeaveType(0);
    }
    this.getPosition();
    this.getListLeave();
    if (this.$store.getters.locale == "vi") {
      this.configDateTimePicker.locale = FlatpickrLanguages["vn"];
    }
  },
  methods: {
    hideModalDelete() {
      this.$refs["modal-delete"].hide();
    },

    hidenModalApproval() {
      this.$refs["modal-approval"].hide();
    },

    hideModalInfoDetail() {
      this.$refs["modal-info-detail"].hide();
    },

    hidemodalAddLeave() {
      this.$refs["modal-add-leave"].hide();
    },

    showDelete(idDelete) {
      this.idDelete = idDelete;
      this.showDeleteSuccess = false;
      this.modalDelete = true;
    },
    showEdit(id) {
      this.editLeave = {
        id: id,
      };
      this.showApprovalSuccess = false;
      this.modalApproval = true;
    },
    showModalAddLeave() {
      this.dateSelect = "";
      this.idPerson = "";
      this.reasonForLeave = "";
      this.optionsIdPerson = [];
      this.leaveType = null;
      this.leaveHalfShift = false;
      this.approveList = [];
      this.modalAddLeave = true;
      this.msg = [];
    },
    addMoreApprove() {
      this.approveList.push({
        positionOption: this.listPosition,
        position: null,
        listUser: [],
        user: null,
      });
    },
    changeBranch() {
      if (this.branchid != null) {
        this.getPosition();
      }
    },
    changePosition(index) {
      setTimeout(() => {
        this.getApproverByPosition(index);
      }, 500);
    },
    async getPosition() {
      let res;
      try {
        res = await apiServer.post("api/v1/position/list", {
          placeId: this.branchid,
        });
      } catch (error) {}
      if (res && res.data) {
        this.listPosition = [];
        if (res.data.length > 0) {
          this.listPosition = res.data;
          for (var i = 0; i < this.approveList.length; i++) {
            this.approveList.positionOption = this.listPosition;
          }
        }
      }
    },
    async getApproverByPosition(index) {
      let res;
      try {
        let role_id =
          this.idPerson != null && this.idPerson != ""
            ? this.idPerson.role_id
            : null;
        res = await apiServer.post("api/v1/user/search-list-approver", {
          position: this.approveList[index].position,
          role: role_id,
        });
      } catch (error) {}
      if (res && res.data) {
        this.approveList[index].listUser = [];
        if (res.data.length > 0) {
          this.approveList[index].listUser = res.data;
        }
      }
    },
    async showListUser(search, loading) {
      let res;
      try {
        res = await apiServer.post("api/v1/user/suggest-user", {
          placeID: this.$store.getters.placeID,
          keyword: search,
        });
      } catch (error) {}
      if (res && res.data) {
        if (res.data.length > 0) {
          this.optionsIdPerson = [];
          for (let user of res.data) {
            this.optionsIdPerson.push({
              value: user.id,
              text: user.fullName,
              email: user.email,
              avatar: user.avatar,
              placeId: user.placeId,
              role_id: user.role_id,
            });
          }
          loading(false);
        } else {
          this.notFound = true;
          this.optionsIdPerson = [];
          loading(false);
        }
      }
    },
    selectPerson() {
      this.getListLeaveType(this.idPerson.placeId);
    },
    setLeaveHalf(evt) {
      if (evt === true) {
        this.dateSelect = null;
        this.configDateTimePicker.mode = "single";
      } else {
        this.configDateTimePicker.mode = "range";
        this.leaveTypeHalf = null;
      }
    },
    changeIdPerson(search, loading) {
      if (search.length) {
        loading(true);
        this.showListUser(search, loading);
      }
    },

    async showInfoDetail(item) {
      let res;
      try {
        res = await apiServer.post("/api/v1/leave/get-by-id", {
          leave_id: item.id,
        });
        if (res && res.data) {
          this.infoSenderDetail = res.data;
        }
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
      this.modalInfoDetail = true;
    },
    deniedLeave() {
      this.modalDenied = true;
    },

    async getListLeave() {
      this.loading = true;
      let res;
      try {
        this.dataTable = [];
        res = await apiServer.post("/api/v1/leave/get-list", {
          username: this.userName.trim(),
          approve: this.status,
          palceid: this.idBranch,
        });
        if (res && res.data) {
          this.dataTable = res.data;
          this.loading = false;
        }
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },

    async deleteLeave(id) {
      this.loading = true;
      let res;
      try {
        res = await apiServer.post("/api/v1/leave/delete", {
          id: id,
        });
        if (res && res.data) {
          this.modalDelete = false;
          this.$notify({
            group: "bottomnotification",
            title: this.$t("label.notification"),
            text: this.$t(res.data.message),
            position: "bottom-center",
          });
          this.getListLeave();
        }
        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },
    async approvalLeave(status) {
      this.loading = true;
      let res;
      try {
        res = await apiServer.post("/api/v1/leave/save", {
          id: this.editLeave.id,
          approved: status,
          reasonDenied: this.reasonDenied.trim(),
        });
        if (res && res.data) {
          this.getListLeave();
          this.editLeave = [];
          this.$notify({
            group: "bottomnotification",
            title: this.$t("label.notification"),
            text: this.$t(res.data.message),
            position: "bottom-center",
          });
          this.modalDenied = false;
          this.modalApproval = false;
          this.loading = false;
        }
      } catch (e) {
        this.loading = false;
      }
    },

    async addLeave() {
      let res;
      let df = null,
        dt = null;
      if (this.dateSelect != undefined && this.dateSelect !== "") {
        let dateArr = this.dateSelect.split(" ");
        df = this.convertTimestampSendDate(dateArr[0]);
        if (dateArr.length == 1) {
          dt = df;
        } else {
          dt = this.convertTimestampSendDate(dateArr[2]);
        }
      }
      let user_id =
        this.idPerson != null && this.idPerson != ""
          ? this.idPerson.value
          : null;
      var approveUser = [];
      for (var i = 0; i < this.approveList.length; i++) {
        approveUser.push(this.approveList[i].user);
      }
      if (approveUser.length === 0) {
        this.$notify({
          group: "bottomnotification",
          title: this.$t("label.notificationError"),
          text: this.$t("error.message.userApproveEmpty"),
          type: "error",
        });
        return false;
      }
      if (this.hasDuplicates(approveUser)) {
        this.$notify({
          group: "bottomnotification",
          title: this.$t("label.notificationError"),
          text: this.$t("error.message.userApproveDuplicate"),
          type: "error",
        });
        return false;
      }
      this.loading = true;
      try {
        res = await apiServer.post("/api/v1/leave/save", {
          userid: user_id,
          leaveType: this.leaveType,
          offworkfrom: df,
          offworkto: dt,
          leaveHalfShift: this.leaveHalfShift,
          leaveTypeHalf: this.leaveTypeHalf,
          reason: this.reasonForLeave,
          approved: false,
          approveUsers: approveUser,
        });
        if (res && res.data) {
          if (res.status === 200) {
            this.modalAddLeave = false;
            this.$notify({
              group: "bottomnotification",
              title: this.$t("label.notification"),
              text: this.$t(res.data.message),
              position: "bottom-center",
            });
            this.getListLeave();
          }
          this.loading = false;
        }
      } catch (error) {
        this.loading = false;
        if (error.response.status === 400) {
          this.msg = error.response.data;
        }
        if (error.response.data["message"]) {
          this.$notify({
            group: "bottomnotification",
            title: this.$t("label.notificationError"),
            text: this.$t(this.msg["message"]),
            type: "error",
          });
        } else {
          console.log(error.response.data);
        }
      }
    },

    async getListLeaveType(placeId) {
      let res;
      try {
        res = await apiServer.post("/api/v1/leave-type/get-list", {
          placeId: placeId,
        });
        if (res && res.data) {
          this.leaveTypes = res.data.map(function (val, index) {
            return { value: val.id, text: val.code + " - " + val.name };
          });
        }
      } catch (error) {
        if (error.response.status === 400) {
          console.log(error.response.message);
        }
      }
    },

    async getListBranch() {
      let res;
      try {
        res = await apiServer.post("/api/v1/company/get-all-branch", {});
        if (res && res.data) {
          this.branchs.push({
            value: null,
            text: this.$t("label.allBranches"),
          });
          for (let br of res.data) {
            this.branchs.push({ value: br.placeID, text: br.branch_name });
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    async downloadFileTemplate() {
      let res;
      try {
        res = await apiServer
          .get("/api/v1/leave/downloadTemplate", {
            responseType: "blob",
          })
          .then((response) => {
            const url = URL.createObjectURL(
              new Blob([response.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "leave");
            document.body.appendChild(link);
            link.click();
          });
      } catch (error) {
        console.log(error);
      }
    },
    async importLeave() {
      let file = this.$refs.file.files[0];
      if (file == null) {
        alert(this.$t("error.message.noSelectExcelFile"));
        return false;
      }
      if (this.isSupperAdmin || this.isComAdmin) {
        this.modalImport = true;
      } else {
        await this.confirmImportLeave();
      }
    },
    async confirmImportLeave() {
      let formData = new FormData();
      formData.append("file", this.$refs.file.files[0]);
      // formData.append("file", files[0]);
      const config = {
        header: {
          "Content-Type": "multipart/form-data",
        },
      };
      let res;
      this.msg = [];
      try {
        this.loading = true;
        res = await apiServer.post("api/v1/leave/import", formData, config);
      } catch (error) {
        if (error.response.status === 400) {
          this.loading = false;
          this.msg = error.response.data;
          await this.getListLeave();
        }
      } finally {
        this.loading = false;
      }
      if (res && res.data) {
        this.$notify({
          group: "bottomnotification",
          title: this.$t("label.notification"),
          text: this.$t(res.data.message),
          position: "bottom-center",
        });
        await this.getListLeave();
      }
    },
    search() {
      this.getListLeave();
    },
    getStatus(status) {
      if (status == 1) {
        return this.$t("label.agree");
      } else if (status == 2) {
        return this.$t("label.pending");
      } else if (status == 3) {
        return this.$t("label.refuse");
      } else {
        return this.$t("label.waitingForApprove");
      }
    },
    convertDate(inputDate) {
      if (inputDate == undefined || inputDate == null) return "";
      return moment(inputDate).format("DD/MM/yyyy");
    },
    hasDuplicates(arr) {
      return arr.some((x) => arr.indexOf(x) !== arr.lastIndexOf(x));
    },
    tookABreak(dateId) {
      let today = new Date();
      let dt = new Date(dateId);
      let dat2 = today.getTime() - dt.getTime();
      let dat3 = Math.ceil(dat2 / (1000 * 60 * 60 * 24));
      return dat3;
    },
    convertTimestampSendDate(date) {
      const dateString = date;
      const changedDate = dateString.replace(/(..)\/(..)\/(....)/, "$3-$2-$1");
      var date = new Date(changedDate);
      return moment(date).format("yyyy-MM-DD");
    },
  },
};
</script>

<style lang="scss" scoped>
.show-user-list {
  padding: 5px 0;
  .col-2 {
    img {
      height: 50px;
    }
  }
  .col-10 {
    padding: 0;
    h5 {
      margin-bottom: 0;
    }
    p {
      color: #333;
      margin-bottom: 0;
    }
    &:hover {
      h5 {
        color: #fff;
      }
      p {
        color: #fff;
        margin-bottom: 0;
      }
    }
  }
  &:hover {
    outline: none;
  }
}
.table p {
  word-wrap: break-word;
  white-space: break-spaces;
}
</style>
<style lang="scss">
.b-form-checkbox {
  margin-bottom: 1.5rem;
  label {
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }
}
.button-mb {
  margin-bottom: 3.5px !important;
}
</style>
